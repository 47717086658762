<template>
  <div>
    <v-app>
      <core-view v-if="currentProgram && !loading" />
      <NoProgram v-else />
      <snack-bar />
    </v-app>
  </div>
</template>

<script>
export default {
  components: {
    CoreView: () => import("@/components/core/View"),
    SnackBar: () => import("@/components/core/Snackbar"),
    NoProgram: () =>
      import(
        "@/components/Design/View/ViewComponents/Shared/Authentication/NoProgram.vue"
      )
  },
  computed: {
    loading() {
      return this.$store.getters.loading;
    },
    admin() {
      return this.$store.getters.admin;
    },
    programId() {
      return this.$store.getters.programId;
    },
    selectedPageTemplate() {
      return this.$store.getters.selectedPageTemplate;
    },
    currentUser() {
      return this.$store.getters.currentUser;
    },
    currentProgram() {
      return this.$store.getters.currentProgram;
    },
    firstTimeLoad() {
      return this.$store.getters.firstTimeLoad;
    }
  },
  methods: {
    setLanguage() {}
  },
  async beforeCreate() {
    await this.$store.dispatch("setLoading", true);
    await this.$store.dispatch("setProgramId");
    await this.$store.dispatch("loadTranslations");
    await this.$store.dispatch("loadTranslationData");
    this.$i18n.locale = this.$store.getters.initialLocale;
    if (this.$store.getters.firstTimeLoad) {
      await this.$store.dispatch("setFirstTimeLoad", false);
      this.$router.go(0);
      await this.$store.dispatch("setLoading", false);
    }
    await this.$store.dispatch("setLoading", false);
  },
  async created() {
    favicon.setAttribute("href", "surfaceimports.png");
    // await this.$store.dispatch("setLoading", false);
    if (this.currentUser) {
      await this.$store.dispatch("getCurrentUser");
      this.$store.dispatch("getProgressData", this.currentUser);
      this.$store.dispatch("getPrimaryMenus", this.currentUser);
      this.$store.dispatch("getUserDealer", this.currentUser);
      this.$store.dispatch("getSecondaryMenus", this.currentUser);
      this.$store.dispatch("getPromotionList", this.currentUser);
      this.$store.dispatch("getRewardList", this.currentUser);
      this.$store.dispatch("getOfferList", this.currentUser);
      this.$store.dispatch("loadQuizzes");
    }
  }
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Caveat:wght@400;500;600;700&family=Cinzel:wght@400;500;600;700;800;900&family=Lobster&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Merriweather:ital,wght@0,300;0,400;0,700;0,900;1,300;1,400;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Pacifico&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Satisfy&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Spectral:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Figtree:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Quicksand:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Urbanist:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.lottie {
  position: absolute;
  z-index: 1000;
}
.lottie-full-width {
  position: absolute;
  z-index: 1000;
  width: 100%;
}
.lottie-full-height {
  position: absolute;
  z-index: 1000;
  height: 100%;
}
.incentable {
  color: #e91e63;
}
.page-bar {
  margin-top: 128px !important;
}
.size-label {
  color: rgba(0, 0, 0, 0.6);
  letter-spacing: normal;
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  margin-top: 3px;
}
.page {
  padding: 0px 0px 0px 0px !important;
}
.program-page {
  padding: 20px 00px 0px 0px !important;
}
.el {
  box-shadow: rgba(0, 0, 0, 0.04) 0px 5px 6px -3px,
    rgba(0, 0, 0, 0.06) 0px 9px 12px 1px, rgba(0, 0, 0, 0.05) 0px 3px 16px 2px !important;
}
.v-application {
  font-weight: 400 !important;
  font-size: 15px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.metric {
  font-size: 36px !important;
  font-weight: 400 !important;
}
.switch {
  margin-top: 22px !important;
}
.switch-label {
  margin-top: 2px !important;
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  text-transform: uppercase !important;
  letter-spacing: 0.0892857143em;
  text-indent: 0.0892857143em;
  white-space: nowrap;
}
.text-icon {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 900 !important;
  color: #616161 !important;
}
.vue-dropzone {
  font-weight: 400 !important;
  font-size: 12px !important;
  font-family: "Lato", sans-serif !important;
  color: #ffffff !important;
}
.icon {
  color: #616161 !important;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #2e2e2e !important;
}
.theme-menu {
  font-family: "Roboto", sans-serif !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  color: #757575 !important;
  line-height: 1.2;
}
.award {
  font-weight: 600 !important;
  font-size: 15px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.link {
  text-decoration: none !important;
}
.back-link {
  font-weight: 400 !important;
  font-size: 11px !important;
  font-family: "Roboto", sans-serif !important;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
.filename {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  line-height: 1em;
}
.program-name {
  font-family: "Roboto", sans-serif !important;
  font-size: 20px;
  font-weight: 300 !important;
  color: white !important;
}
.domain-name {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #acacac !important;
}
.filename {
  font-size: 14px;
  font-weight: 700;
}
.alert-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 13px !important;
  font-weight: 400 !important;
}
.card-title {
  font-size: 15pt;
  font-weight: 300;
  letter-spacing: 1px;
}
.toolbar-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: white !important;
}
.page-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 20px !important;
  font-weight: 300 !important;
  color: #4e4e4e !important;
}
.v-breadcrumbs__item {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 300 !important;
  color: white !important;
}
.toolbar-card-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  color: white !important;
}
.toolbar-card-subheading {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 200 !important;
  color: white !important;
}
.v-label {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
}
.v-btn {
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
  margin: 4px;
}
.v-card__text,
.v-card__title {
  word-break: normal !important;
}
.v-list-item__title {
  font-size: 14px !important;
  font-weight: 400 !important;
}
.tab {
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 500 !important;
}
.incentable-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 30px;
  font-weight: 300 !important;
}
.page-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  color: #4e4e4e !important;
}
.incentable-page-subheading {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #4e4e4e !important;
}
.map-header {
  font-weight: 400 !important;
  background-color: #e0e0e0 !important;
}
.map-col-1 {
  background-color: #e0e0e0 !important;
  padding: 4px;
}
.map-cell {
  padding: 4px;
  margin: 0px 1px 1px 0px;
  min-height: 30px;
}
.import-form-header {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px;
  font-weight: 600 !important;
  padding: 10px;
  margin: 0px 1px 1px 0px;
  min-height: 40px;
  background-color: #e0e0e0 !important;
}
.import-form {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px;
  font-weight: 400 !important;
  padding: 10px;
  margin: 0px 1px 1px 0px;
  min-height: 42px;
}
.import-select {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px !important;
  font-weight: 400 !important;
  margin: -4px 0px 0px 0px !important;
  padding: -10px 0px 0px 0px !important;
  width: 210px;
  height: 38px !important;
}
.dash-card-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 15px;
  font-weight: 400 !important;
  margin-left: 0px !important;
  margin-bottom: 4px !important;
  color: #4e4e4e !important;
}
.file-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 500 !important;
  margin-left: 2px !important;
  margin-bottom: 4px !important;
  color: #4e4e4e !important;
}
.dash-card {
  font-family: "Roboto", sans-serif !important;
  font-size: 11px;
  font-weight: 300 !important;
  line-height: 1.2;
  color: #acacac !important;
}
.incentable-form-bold {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px;
  font-weight: 500 !important;
  color: #4e4e4e !important;
}
.incentable-formula-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #4e4e4e !important;
}
.incentable-form-heading {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #4e4e4e !important;
}
.incentable-form-subheading {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  color: #4e4e4e !important;
  margin-top: -8px;
  margin-bottom: 6px;
  line-height: 2.5;
}
.incentable-alert {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
}
.close-icon {
  position: absolute;
  margin: -7px -17px;
}
.incentable-leaderboard-table-title-row {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding: 12px 22px !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.table-title-row {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding: 18px 22px !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.table-cell {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  background-color: #f1f1f1 !important;
  padding: 10px 20px !important;
  line-height: 34px;
}
.incentable-csv-table-title-row {
  font-family: "Roboto", sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  padding: 10px !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.incentable-csv-table-cell {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
}
.table-total-row {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  padding: 10px !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.movable {
  cursor: move;
}
.table-first-col {
  margin-right: 1px !important;
  padding: 5px 20px !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.table-icon {
  margin-right: 1px !important;
  margin-bottom: 2px !important;
  background-color: #1d1d1d !important;
  padding-top: 3px;
  padding-left: 3px;
  padding-bottom: 2px;
  line-height: 42px;
}
.table-icon-cell {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-right: 1px !important;
  margin-bottom: 1px !important;
  background-color: #ffffff !important;
  padding-top: 1px;
  line-height: 34px;
  min-width: 45px;
}
.incentable-points-table-title-row {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #4e4e4e !important;
}
.cell-trim {
  padding-left: -6px;
  padding-right: -6px;
}
.operator {
  font-family: "Roboto", sans-serif !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  background-color: #e0e0e0;
  color: #2e2e2e !important;
}
.andor {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  background-color: #e0e0e0;
  color: #2e2e2e !important;
}
.rotate {
  transform: rotate(90deg);
}
.rotate180 {
  transform: rotate(180deg);
}
.rotate270 {
  transform: rotate(270deg);
}
.table-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #4e4e4e !important;
  background-color: #e0e0e0 !important;
}
.table-subtitle {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  font-weight: 400 !important;
  color: #4e4e4e !important;
}
.incentable-databucket-table-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #888888 !important;
}
.incentable-databucket-table-subtitle {
  font-family: "Roboto", sans-serif !important;
  font-size: 10px !important;
  font-weight: 400 !important;
  color: #888888 !important;
}
.table-right {
  text-align: right;
  padding-right: 8px;
}
.table-left {
  text-align: left;
  padding-left: 8px;
}
.incentable-card-title {
  font-family: "Roboto", sans-serif !important;
  font-size: 18px !important;
  font-weight: 300 !important;
  margin-left: 8px;
  margin-right: 10px;
  margin-top: 0px;
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #4e4e4e !important;
}
.incentable-create-card-subtitle {
  font-family: "Roboto", sans-serif !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  margin-left: 8px !important;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
  color: #4e4e4e !important;
}
.incentable-card-subtitle {
  font-family: "Roboto", sans-serif !important;
  font-size: 13px !important;
  font-weight: 300 !important;
  margin-left: 8px !important;
  margin-top: -20px !important;
  margin-bottom: 0px !important;
  color: #4e4e4e !important;
}
.incentable-card-action-text {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  margin-left: 8px !important;
  margin-top: 4px !important;
  margin-bottom: 4px !important;
  color: #4e4e4e !important;
}
.incentable-dash-card-subtitle {
  font-family: "Roboto", sans-serif !important;
  font-size: 14px !important;
  font-weight: 300 !important;
  color: #4e4e4e !important;
}
.tab-table-padding {
  padding-left: 0px !important;
  padding-right: 0px !important;
  padding-top: 24px !important;
}
.menu-heading-label {
  font-family: "Roboto", sans-serif !important;
  font-size: 11px !important;
  font-weight: 400 !important;
  color: #acacac !important;
  margin-left: 14px;
}
.menu-label {
  font-weight: 300 !important;
  font-size: 13px !important;
  font-family: "Roboto", sans-serif !important;
  color: #e6e6e6 !important;
}
.v-list-item .v-list-item__subtitle,
.v-list-item .v-list-item__title {
  line-height: 2 !important;
}
.v-input {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  font-weight: 400 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  font-weight: 500 !important;
  font-size: 14px !important;
  font-family: "Roboto", sans-serif !important;
  color: #4e4e4e !important;
}
.grid-block {
  border: 1px dashed grey !important;
  background-color: transparent !important;
}
.grid-outer {
  border: 1px dotted grey !important;
  background-color: transparent !important;
}
.grid-inner {
  border: 1px dotted white !important;
  background-color: transparent !important;
}
.grid-off {
  border: 1px dotted transparent !important;
  background-color: transparent !important;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.link {
  cursor: pointer;
}
</style>
