import { db } from "@/firebase";

const state = {
  progressData: [],
  leaderBoardData: [],
  tableData: [],
  promotionList: {},
  rewardList: {},
  primaryMenus: [],
  secondaryMenus: [],
  tablesDisplay: [],
  leaderboardsDisplay: []
};

const actions = {
  async getProgressData({ commit, getters }, user) {
    // console.log('getters.programId: ', getters.programId)
    const dbRef = db.collection("programs").doc(getters.programId);
    const leaderboardUnsubscribe = dbRef
      .collection("leaderboards")
      .orderBy("order")
      .onSnapshot(
        querySnapshot => {
          var leaderBoardDataArr = [];
          let currentUserLeaderboardArr = [];
          querySnapshot.forEach(doc => {
            let leaderBoardData = {
              ...doc.data(),
              tableId: doc.id
            };
            if (
              leaderBoardData.status === "Active" ||
              leaderBoardData.status === "active"
            ) {
              let memberTagExist = false;
              if (leaderBoardData.memberTags.length === 0) {
                memberTagExist = true;
              } else {
                memberTagExist = leaderBoardData.memberTags.some(tag => {
                  return user.tags.includes(tag);
                });
              }
              let companyTagExist = false;
              if (leaderBoardData.companyTags.length === 0) {
                companyTagExist = true;
              } else {
                companyTagExist = leaderBoardData.companyTags.some(tag => {
                  return user.companyTags.includes(tag);
                });
              }
              if (memberTagExist && companyTagExist) {
                const rankMap =
                  leaderBoardData.columnMappings.find(
                    item => item.column === "Rank"
                  ) || {};
                let updated = false;
                if (leaderBoardData.participantType === "member") {
                  leaderBoardData.columnData.map(leaderboardObj => {
                    if (leaderboardObj.id === user._id) {
                      currentUserLeaderboardArr.push({
                        ...leaderboardObj,
                        title: leaderBoardData.title,
                        displayTitle: leaderBoardData.displayTitle,
                        order: leaderBoardData.order,
                        prefix:
                          leaderBoardData.homeMappings &&
                          leaderBoardData.homeMappings.prefix,
                        suffix:
                          leaderBoardData.homeMappings &&
                          leaderBoardData.homeMappings.suffix,
                        cardHeader: leaderBoardData.headerMappings,
                        headerData:
                          leaderBoardData.headerData.find(
                            el => el.id === leaderboardObj.id
                          ) || {},
                        resultsHeader: leaderBoardData.columnMappings,
                        tableId: leaderBoardData.tableId,
                        cardType: "leaderboard",
                        rankValue: leaderboardObj.values[rankMap.uid],
                        iconToRender: leaderBoardData.iconMappings || {},
                        data: leaderBoardData.columnData,
                        remarkData:
                          leaderBoardData.remarkData.find(
                            el => el.id === leaderboardObj.id
                          ) || {},
                        remarkMappings: leaderBoardData.remarkMappings
                      });
                      updated = true;
                    }
                  });
                } else {
                  leaderBoardData.columnData.map(leaderboardObj => {
                    user.companies.map(companyId => {
                      if (leaderboardObj.id === companyId) {
                        currentUserLeaderboardArr.push({
                          ...leaderboardObj,
                          title: leaderBoardData.title,
                          displayTitle: leaderBoardData.displayTitle,
                          order: leaderBoardData.order,
                          prefix:
                            leaderBoardData.homeMappings &&
                            leaderBoardData.homeMappings.prefix,
                          suffix:
                            leaderBoardData.homeMappings &&
                            leaderBoardData.homeMappings.suffix,
                          cardHeader: leaderBoardData.headerMappings,
                          headerData:
                            leaderBoardData.headerData.find(
                              el => el.id === leaderboardObj.id
                            ) || {},
                          resultsHeader: leaderBoardData.columnMappings,
                          tableId: leaderBoardData.tableId,
                          cardType: "leaderboard",
                          rankValue: leaderboardObj.values[rankMap.uid],
                          iconToRender: leaderBoardData.iconMappings || {},
                          data: leaderBoardData.columnData,
                          remarkData:
                            leaderBoardData.remarkData.find(
                              el => el.id === leaderboardObj.id
                            ) || {},
                          remarkMappings: leaderBoardData.remarkMappings || {}
                        });
                        updated = true;
                      }
                    });
                  });
                }
                if (!updated) {
                  currentUserLeaderboardArr.push({
                    title: leaderBoardData.title,
                    displayTitle: leaderBoardData.displayTitle,
                    order: leaderBoardData.order,
                    prefix:
                      leaderBoardData.homeMappings &&
                      leaderBoardData.homeMappings.prefix,
                    suffix:
                      leaderBoardData.homeMappings &&
                      leaderBoardData.homeMappings.suffix,
                    cardHeader: leaderBoardData.headerMappings,
                    headerData: null,
                    resultsHeader: leaderBoardData.columnMappings,
                    tableId: leaderBoardData.tableId,
                    cardType: "leaderboard",
                    rankValue: null,
                    iconToRender: leaderBoardData.iconMappings || {},
                    data: leaderBoardData.columnData,
                    remarkData: {},
                    remarkMappings: leaderBoardData.remarkMappings
                  });
                }
              }
              leaderBoardDataArr.push(leaderBoardData);
            }
          });
          // console.log(currentUserLeaderboardArr)
          commit("setLeaderBoardData", currentUserLeaderboardArr);
        },
        error => {
          console.log(error);
          if (leaderboardUnsubscribe) {
            leaderboardUnsubscribe();
          }
        }
      );

    const tableUnsubscribe = dbRef
      .collection("tables")
      .orderBy("order")
      .onSnapshot(
        querySnapshot => {
          let currentUserResultData = [];
          var resultDataArray = [];
          querySnapshot.forEach(doc => {
            let resultData = {
              ...doc.data(),
              tableId: doc.id
            };
            if (
              resultData.status === "Active" ||
              resultData.status === "active"
            ) {
              let memberTagExist = false;
              if (resultData.memberTags.length === 0) {
                memberTagExist = true;
              } else {
                memberTagExist = resultData.memberTags.some(tag => {
                  return user.tags.includes(tag);
                });
              }
              let companyTagExist = false;
              if (resultData.companyTags.length === 0) {
                companyTagExist = true;
              } else {
                companyTagExist = resultData.companyTags.some(tag => {
                  return user.companyTags.includes(tag);
                });
              }
              if (memberTagExist && companyTagExist) {
                if (resultData.participantType === "member") {
                  resultData.tableData.map(resultObj => {
                    if (resultObj.companyId === user._id) {
                      currentUserResultData.push({
                        ...resultObj,
                        title: resultData.title,
                        displayTitle: resultData.displayTitle,
                        order: resultData.order,
                        prefix: resultData.homeCard.prefix,
                        suffix: resultData.homeCard.suffix,
                        cardHeader: resultData.cardHeader,
                        resultsHeader: resultData.resultsHeader,
                        tableId: resultData.tableId
                      });
                    }
                  });
                } else {
                  user.companies.map((company, index) => {
                    resultData.tableData.map(resultObj => {
                      if (resultObj.companyId === company) {
                        currentUserResultData.push({
                          ...resultObj,
                          title: resultData.title,
                          displayTitle: resultData.displayTitle,
                          order: resultData.order,
                          prefix: resultData.homeCard.prefix,
                          suffix: resultData.homeCard.suffix,
                          cardHeader: resultData.cardHeader,
                          resultsHeader: resultData.resultsHeader,
                          tableId: resultData.tableId
                        });
                      }
                    });
                  });
                }
              }
              resultDataArray.push(resultData);
            }
          });
          // console.log('Array', currentUserResultData)
          commit("setTableData", currentUserResultData);
        },
        error => {
          console.log(error);
          if (tableUnsubscribe) {
            tableUnsubscribe();
          }
        }
      );
  },

  async getPromotionList({ commit, getters }, user) {
    const dbRef = db.collection("programs").doc(getters.programId);
    const rootUnsubscribe = dbRef.onSnapshot(
      querySnapshot => {
        let allData = querySnapshot.data();
        let homeBlogIds = allData.homeBlogs.blogs;
        // let previews = +allData.homeBlogs.previews
        let promotionList = {};
        if (homeBlogIds.length !== 0) {
          homeBlogIds.map(async (id, index) => {
            await new Promise((resolve, reject) => {
              let articleUnsubscribe = [];
              articleUnsubscribe = dbRef
                .collection("articles")
                .orderBy("updated", "desc")
                .where("blog", "==", id)
                .where("status", "==", "Active")
                .onSnapshot(
                  async querySnapshot => {
                    // console.log(querySnapshot, 'promotionList')
                    const blogDataArr = [];
                    const createrObj = {};
                    querySnapshot.forEach(doc => {
                      let blogData = doc.data();
                      let creater = blogData.updatedBy;
                      let tags = [];

                      // tags logic
                      let memberTagExist = false;
                      if (blogData.memberTags.length === 0) {
                        memberTagExist = true;
                      } else {
                        memberTagExist = blogData.memberTags.some(tag => {
                          return user.tags.includes(tag);
                        });
                      }
                      let companyTagExist = false;
                      if (blogData.companyTags.length === 0) {
                        companyTagExist = true;
                      } else {
                        companyTagExist = blogData.companyTags.some(tag => {
                          return user.companyTags.includes(tag);
                        });
                      }
                      if (
                        memberTagExist &&
                        companyTagExist &&
                        blogDataArr.length < 10
                      ) {
                        // we need to fix this later if we have time
                        blogData.categorys.map(eachTagInfo => {
                          const categoryUnsubscribe = dbRef
                            .collection("categorys")
                            .doc(eachTagInfo)
                            .onSnapshot(
                              querySnapshot => {
                                tags.push(querySnapshot.data().title);
                              },
                              error => {
                                console.log(error);
                                if (categoryUnsubscribe) {
                                  categoryUnsubscribe();
                                }
                              }
                            );
                          blogData.tagsToRender = tags;
                        });

                        blogData.authorId = creater;
                        blogData.id = doc.id;
                        blogDataArr.push(blogData);
                        if (!(creater in createrObj)) {
                          createrObj[creater] = {};
                        }
                      }
                    });

                    // fetch users info to display user name
                    const userFetchRequests = Object.keys(createrObj).map(
                      creater =>
                        db
                          .collection("admins")
                          .doc(creater)
                          .get()
                    );
                    const userDocs = await Promise.all(userFetchRequests);
                    userDocs.forEach(doc => {
                      const user = doc.data();
                      if (user) {
                        createrObj[user.userId] = user;
                      }
                    });
                    if (promotionList.hasOwnProperty(id)) {
                      promotionList[id] = blogDataArr.map(el => ({
                        ...el,
                        author: createrObj[el.authorId]
                          ? createrObj[el.authorId].name
                          : "",
                        created: el.created.toDate(),
                        updated: el.updated.toDate()
                      }));
                    } else {
                      const payload = {
                        [id]: blogDataArr.map(el => ({
                          ...el,
                          author: createrObj[el.authorId]
                            ? createrObj[el.authorId].name
                            : "",
                          created: el.created.toDate(),
                          updated: el.updated.toDate()
                        }))
                      };
                      promotionList = {
                        ...promotionList,
                        ...payload
                      };
                    }
                    // update promotion list
                    // console.log(promotionList)
                    commit("setPromotionList", promotionList);
                    resolve();
                  },
                  error => {
                    console.log(error.nativeErrorMessage);
                    reject(error.nativeErrorMessage);
                    if (articleUnsubscribe) {
                      articleUnsubscribe();
                    }
                  }
                );
            });
          });
        }
      },
      err => {
        console.log(err, "err --- promotionList");
        if (rootUnsubscribe) {
          rootUnsubscribe();
        }
      }
    );
  },

  async getRewardList({ commit, getters }, user) {
    const dbRef = db.collection("programs").doc(getters.programId);
    const rootUnsubscribe = dbRef.onSnapshot(
      querySnapshot => {
        let allData = querySnapshot.data();
        let homeRewardCatalogueIds =
          allData.homeRewardCatalogues.rewardCatalogues || {};
        // console.log(homeRewardCatalogueIds)
        // let previews = +allData.homerewardCatalogues.previews
        let rewardList = {};
        if (homeRewardCatalogueIds.length !== 0) {
          homeRewardCatalogueIds.map(async (id, index) => {
            await new Promise((resolve, reject) => {
              const rewardUnsubscribe = dbRef
                .collection("rewards")
                .orderBy("updated", "desc")
                .where("rewardCatalogue", "==", id)
                .where("status", "==", "Active")
                .onSnapshot(
                  async querySnapshot => {
                    // console.log(querySnapshot, 'rewardList')
                    const rewardCatalogueDataArr = [];
                    const createrObj = {};
                    querySnapshot.forEach(doc => {
                      let rewardCatalogueData = doc.data();
                      let creater = rewardCatalogueData.updatedBy;
                      let tags = [];

                      // tags logic
                      let memberTagExist = false;
                      if (rewardCatalogueData.memberTags.length === 0) {
                        memberTagExist = true;
                      } else {
                        memberTagExist = rewardCatalogueData.memberTags.some(
                          tag => {
                            return user.tags.includes(tag);
                          }
                        );
                      }
                      let companyTagExist = false;
                      if (rewardCatalogueData.companyTags.length === 0) {
                        companyTagExist = true;
                      } else {
                        companyTagExist = rewardCatalogueData.companyTags.some(
                          tag => {
                            return user.companyTags.includes(tag);
                          }
                        );
                      }
                      if (
                        memberTagExist &&
                        companyTagExist &&
                        rewardCatalogueDataArr.length < 10
                      ) {
                        // we need to fix this later if we have time
                        rewardCatalogueData.rewardCategories.map(
                          eachTagInfo => {
                            const rewardCategoryUnsubscribe = dbRef
                              .collection("rewardCategories")
                              .doc(eachTagInfo)
                              .onSnapshot(
                                querySnapshot => {
                                  tags.push(querySnapshot.data().title);
                                },
                                error => {
                                  console.log(error);
                                  if (rewardCategoryUnsubscribe) {
                                    rewardCategoryUnsubscribe();
                                  }
                                }
                              );
                            rewardCatalogueData.tagsToRender = tags;
                          }
                        );

                        rewardCatalogueData.authorId = creater;
                        rewardCatalogueData.id = doc.id;
                        rewardCatalogueDataArr.push(rewardCatalogueData);
                        if (!(creater in createrObj)) {
                          createrObj[creater] = {};
                        }
                      }
                    });

                    // fetch users info to display user name
                    const userFetchRequests = Object.keys(createrObj).map(
                      creater =>
                        db
                          .collection("admins")
                          .doc(creater)
                          .get()
                    );
                    const userDocs = await Promise.all(userFetchRequests);
                    userDocs.forEach(doc => {
                      const user = doc.data();
                      if (user) {
                        createrObj[user.userId] = user;
                      }
                    });

                    if (rewardList.hasOwnProperty(id)) {
                      rewardList[id] = rewardCatalogueDataArr.map(el => ({
                        ...el,
                        author: createrObj[el.authorId]
                          ? createrObj[el.authorId].name
                          : "",
                        created: el.created.toDate()
                      }));
                    } else {
                      const payload = {
                        [id]: rewardCatalogueDataArr.map(el => ({
                          ...el,
                          author: createrObj[el.authorId]
                            ? createrObj[el.authorId].name
                            : "",
                          created: el.created.toDate()
                        }))
                      };
                      rewardList = {
                        ...rewardList,
                        ...payload
                      };
                    }
                    // update reward list
                    commit("setRewardList", rewardList);
                    resolve();
                  },
                  error => {
                    console.log(error.nativeErrorMessage);
                    reject(error.nativeErrorMessage);
                    if (rewardUnsubscribe) {
                      rewardUnsubscribe();
                    }
                  }
                );
            });
          });
        }
      },
      err => {
        console.log(err, "err --- rewardList");
        if (rootUnsubscribe) {
          rootUnsubscribe();
        }
      }
    );
  },

  async getPrimaryMenus({ commit, getters }, user) {
    const dbRef = db.collection("programs").doc(getters.programId);
    const menuUnsubscribe = dbRef
      .collection("primaryMenus")
      .where("status", "==", "Active")
      .onSnapshot(
        querySnapshot => {
          let primaryMenusArray = [];
          let tempArray = [];
          querySnapshot.forEach(doc => {
            tempArray.push({ ...doc.data(), id: doc.id });
          });
          tempArray.forEach(async doc => {
            let primaryMenu = doc;

            // blog logic starts here
            // fetch blogs for each menu if content type is blog
            let blogIdToCompare = primaryMenu.content;
            if (primaryMenu.contentType === "Blog") {
              const blogUnsubscribe = dbRef
                .collection("blogs")
                .doc(primaryMenu.content) // this is where tags logic will aplly
                .onSnapshot(
                  querySnapshot => {
                    let eachBlog = querySnapshot.data();
                    primaryMenu.screenTitle = eachBlog.title;
                    primaryMenu.blogs = {};
                    let blogMemberTagExist = false;
                    if (eachBlog.memberTags.length === 0) {
                      blogMemberTagExist = true;
                    } else {
                      blogMemberTagExist = eachBlog.memberTags.some(tag => {
                        return user.tags.includes(tag);
                      });
                    }
                    let blogCompanyTagExist = false;
                    if (eachBlog.companyTags.length === 0) {
                      blogCompanyTagExist = true;
                    } else {
                      blogCompanyTagExist = eachBlog.companyTags.some(tag => {
                        return user.companyTags.includes(tag);
                      });
                    }
                    if (blogMemberTagExist && blogCompanyTagExist) {
                      primaryMenu.hidden = false;
                    } else {
                      primaryMenu.hidden = true;
                    }
                    const articleUnsubscribe = dbRef
                      .collection("articles")
                      .orderBy("updated", "desc")
                      .where("blog", "==", blogIdToCompare)
                      .where("status", "==", "Active")
                      .onSnapshot(
                        async articleQuerySnapshot => {
                          let articleArray = {};
                          // console.log(articleQuerySnapshot, 'primaryMenu')
                          let dynamicCategoriesArray = [];
                          const categoryUnsubscribe = dbRef
                            .collection("categorys")
                            .onSnapshot(
                              async categorySnapshot => {
                                try {
                                  let temp = [];
                                  categorySnapshot.forEach(doc => {
                                    temp.push({
                                      ...doc.data(),
                                      idToCompare: doc.id
                                    });
                                  });
                                  dynamicCategoriesArray = await Promise.all(
                                    temp.map(async doc => {
                                      // run for each doc
                                      let dynamicCategories = doc;
                                      const creater =
                                        dynamicCategories.updatedBy ||
                                        dynamicCategories.createdBy;
                                      const querySnapshot = await db
                                        .collection("admins")
                                        .doc(creater)
                                        .get();
                                      let adminObj = querySnapshot.data();
                                      dynamicCategories.author = adminObj
                                        ? adminObj.name
                                        : "";
                                      return dynamicCategories;
                                    })
                                  );
                                } catch (error) {
                                  console.log(error);
                                  dynamicCategoriesArray = [];
                                }
                                await Promise.all(
                                  dynamicCategoriesArray.map(
                                    async dynamicCategories => {
                                      let articleList = [];
                                      let id = 0;
                                      let articleData = [];
                                      articleQuerySnapshot.forEach(doc => {
                                        articleData.push({
                                          ...doc.data(),
                                          id: doc.id
                                        });
                                      });
                                      await Promise.all(
                                        articleData.map(async article => {
                                          let memberTagExist = false;
                                          if (article.memberTags.length === 0) {
                                            memberTagExist = true;
                                          } else {
                                            memberTagExist = article.memberTags.some(
                                              tag => {
                                                return user.tags.includes(tag);
                                              }
                                            );
                                          }
                                          let companyTagExist = false;
                                          if (
                                            article.companyTags.length === 0
                                          ) {
                                            companyTagExist = true;
                                          } else {
                                            companyTagExist = article.companyTags.some(
                                              tag => {
                                                return user.companyTags.includes(
                                                  tag
                                                );
                                              }
                                            );
                                          }
                                          if (
                                            article.categorys &&
                                            article.status === "Active" &&
                                            companyTagExist &&
                                            memberTagExist
                                          ) {
                                            const creater =
                                              article.updatedBy ||
                                              article.createdBy;
                                            const querySnapshot = await db
                                              .collection("admins")
                                              .doc(creater)
                                              .get();
                                            let adminObj = querySnapshot.data();
                                            article.author = adminObj
                                              ? adminObj.name
                                              : "";
                                            article.created = article.created.toDate();
                                            article.updated = article.updated.toDate();
                                            article.categorys.map(
                                              eachCategory => {
                                                if (
                                                  eachCategory ===
                                                  dynamicCategories.idToCompare
                                                ) {
                                                  dynamicCategories.id = id;
                                                  id++;
                                                  if (
                                                    articleArray.hasOwnProperty(
                                                      dynamicCategories.idToCompare
                                                    )
                                                  ) {
                                                    let index = articleArray[
                                                      dynamicCategories
                                                        .idToCompare
                                                    ].articleList.findIndex(
                                                      el => el.id === article.id
                                                    );
                                                    if (index === -1) {
                                                      articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ].articleList.push({
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      });
                                                      articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ].articleList.sort(
                                                        (a, b) => {
                                                          return (
                                                            new Date(
                                                              b.updated
                                                            ) -
                                                            new Date(a.updated)
                                                          );
                                                        }
                                                      );
                                                    } else {
                                                      articleArray[
                                                        dynamicCategories.idToCompare
                                                      ].articleList[index] = {
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      };
                                                    }
                                                    articleArray[
                                                      dynamicCategories.idToCompare
                                                    ] = {
                                                      ...articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ],
                                                      ...dynamicCategories
                                                    };
                                                  } else {
                                                    let index = articleList.findIndex(
                                                      el => el.id === article.id
                                                    );
                                                    if (index === -1) {
                                                      articleList.push({
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      });
                                                    } else {
                                                      articleList[index] = {
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      };
                                                    }
                                                    dynamicCategories.articleList = [
                                                      ...articleList
                                                    ];
                                                    articleArray[
                                                      dynamicCategories.idToCompare
                                                    ] = {
                                                      ...dynamicCategories
                                                    };
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        })
                                      );
                                      primaryMenu.blogs = articleArray;
                                    }
                                  )
                                );
                                // update menu value
                                let menuIndex = primaryMenusArray.findIndex(
                                  el => el.id === primaryMenu.id
                                );
                                if (menuIndex === -1) {
                                  primaryMenusArray.push(primaryMenu);
                                  primaryMenusArray.sort((a, b) =>
                                    a.order > b.order
                                      ? 1
                                      : b.order > a.order
                                      ? -1
                                      : 0
                                  );
                                  if (
                                    primaryMenusArray.length ===
                                    tempArray.length
                                  ) {
                                    // console.log(primaryMenusArray)
                                    commit(
                                      "setPrimaryMenus",
                                      primaryMenusArray
                                    );
                                  }
                                } else {
                                  primaryMenusArray[menuIndex] = primaryMenu;
                                  if (
                                    primaryMenusArray.length ===
                                    tempArray.length
                                  ) {
                                    // console.log(primaryMenusArray)
                                    commit(
                                      "setPrimaryMenus",
                                      primaryMenusArray
                                    );
                                  }
                                }
                              },
                              error => {
                                console.log(error);
                                if (categoryUnsubscribe) {
                                  categoryUnsubscribe();
                                }
                              }
                            );
                        },
                        error => {
                          console.log(error);
                          if (articleUnsubscribe) {
                            articleUnsubscribe();
                          }
                        }
                      );
                  },
                  error => {
                    console.log(error);
                    if (blogUnsubscribe) {
                      blogUnsubscribe();
                    }
                  }
                );
              // blog logic ends here
              // ***************************************************************************************************************************************
              // rewards logic starts here
            } else if (primaryMenu.contentType === "Reward Catalogue") {
              let rewardCatalogueIdToCompare = primaryMenu.content;
              const rewardCatalogueUnsubscribe = dbRef
                .collection("rewardCatalogues")
                .doc(primaryMenu.content) // this is where tags logic will aplly
                .onSnapshot(
                  querySnapshot => {
                    let eachRewardCatalogue = querySnapshot.data();
                    primaryMenu.screenTitle = eachRewardCatalogue.title;
                    primaryMenu.rewardCatalogues = {};
                    let rewardCatalogueMemberTagExist = false;
                    if (eachRewardCatalogue.memberTags.length === 0) {
                      rewardCatalogueMemberTagExist = true;
                    } else {
                      rewardCatalogueMemberTagExist = eachRewardCatalogue.memberTags.some(
                        tag => {
                          return user.tags.includes(tag);
                        }
                      );
                    }
                    let rewardCatalogueCompanyTagExist = false;
                    if (eachRewardCatalogue.companyTags.length === 0) {
                      rewardCatalogueCompanyTagExist = true;
                    } else {
                      rewardCatalogueCompanyTagExist = eachRewardCatalogue.companyTags.some(
                        tag => {
                          return user.companyTags.includes(tag);
                        }
                      );
                    }
                    if (
                      rewardCatalogueMemberTagExist &&
                      rewardCatalogueCompanyTagExist
                    ) {
                      primaryMenu.hidden = false;
                    } else {
                      primaryMenu.hidden = true;
                    }
                    const rewardUnsubscribe = dbRef
                      .collection("rewards")
                      .orderBy("updated", "desc")
                      .where(
                        "rewardCatalogue",
                        "==",
                        rewardCatalogueIdToCompare
                      )
                      .where("status", "==", "Active")
                      .onSnapshot(
                        async rewardQuerySnapshot => {
                          let rewardArray = {};
                          // console.log(rewardQuerySnapshot, 'primaryMenu')
                          let dynamicRewardCategoriesArray = [];
                          const rewardCategoryUnsubscribe = dbRef
                            .collection("rewardCategories")
                            .onSnapshot(
                              async rewardCategorySnapshot => {
                                try {
                                  let temp = [];
                                  rewardCategorySnapshot.forEach(doc => {
                                    temp.push({
                                      ...doc.data(),
                                      idToCompare: doc.id
                                    });
                                  });
                                  dynamicRewardCategoriesArray = await Promise.all(
                                    temp.map(async doc => {
                                      // run for each doc
                                      let dynamicRewardCategories = doc;
                                      const creater =
                                        dynamicRewardCategories.updatedBy ||
                                        dynamicRewardCategories.createdBy;
                                      const querySnapshot = await db
                                        .collection("admins")
                                        .doc(creater)
                                        .get();
                                      let adminObj = querySnapshot.data();
                                      dynamicRewardCategories.author = adminObj
                                        ? adminObj.name
                                        : "";
                                      return dynamicRewardCategories;
                                    })
                                  );
                                } catch (error) {
                                  console.log(error);
                                  dynamicRewardCategoriesArray = [];
                                }
                                await Promise.all(
                                  dynamicRewardCategoriesArray.map(
                                    async dynamicRewardCategories => {
                                      let rewardList = [];
                                      let id = 0;
                                      let rewardData = [];
                                      rewardQuerySnapshot.forEach(doc => {
                                        rewardData.push({
                                          ...doc.data(),
                                          id: doc.id
                                        });
                                      });
                                      await Promise.all(
                                        rewardData.map(async reward => {
                                          let memberTagExist = false;
                                          if (reward.memberTags.length === 0) {
                                            memberTagExist = true;
                                          } else {
                                            memberTagExist = reward.memberTags.some(
                                              tag => {
                                                return user.tags.includes(tag);
                                              }
                                            );
                                          }
                                          let companyTagExist = false;
                                          if (reward.companyTags.length === 0) {
                                            companyTagExist = true;
                                          } else {
                                            companyTagExist = reward.companyTags.some(
                                              tag => {
                                                return user.companyTags.includes(
                                                  tag
                                                );
                                              }
                                            );
                                          }
                                          if (
                                            reward.rewardCategories &&
                                            reward.status === "Active" &&
                                            companyTagExist &&
                                            memberTagExist
                                          ) {
                                            const creater =
                                              reward.updatedBy ||
                                              reward.createdBy;
                                            const querySnapshot = await db
                                              .collection("admins")
                                              .doc(creater)
                                              .get();
                                            let adminObj = querySnapshot.data();
                                            reward.author = adminObj
                                              ? adminObj.name
                                              : "";
                                            reward.created = reward.created.toDate();
                                            reward.rewardCategories.map(
                                              eachRewardCategory => {
                                                if (
                                                  eachRewardCategory ===
                                                  dynamicRewardCategories.idToCompare
                                                ) {
                                                  dynamicRewardCategories.id = id;
                                                  id++;
                                                  if (
                                                    rewardArray.hasOwnProperty(
                                                      dynamicRewardCategories.idToCompare
                                                    )
                                                  ) {
                                                    let index = rewardArray[
                                                      dynamicRewardCategories
                                                        .idToCompare
                                                    ].rewardList.findIndex(
                                                      el => el.id === reward.id
                                                    );
                                                    if (index === -1) {
                                                      rewardArray[
                                                        dynamicRewardCategories
                                                          .idToCompare
                                                      ].rewardList.push({
                                                        ...reward,
                                                        tagsToRender: [
                                                          dynamicRewardCategories.title
                                                        ]
                                                      });
                                                      rewardArray[
                                                        dynamicRewardCategories
                                                          .idToCompare
                                                      ].rewardList.sort(
                                                        (a, b) => {
                                                          return (
                                                            new Date(
                                                              b.updated
                                                            ) -
                                                            new Date(a.updated)
                                                          );
                                                        }
                                                      );
                                                    } else {
                                                      rewardArray[
                                                        dynamicRewardCategories.idToCompare
                                                      ].rewardList[index] = {
                                                        ...reward,
                                                        tagsToRender: [
                                                          dynamicRewardCategories.title
                                                        ]
                                                      };
                                                    }
                                                    rewardArray[
                                                      dynamicRewardCategories.idToCompare
                                                    ] = {
                                                      ...rewardArray[
                                                        dynamicRewardCategories
                                                          .idToCompare
                                                      ],
                                                      ...dynamicRewardCategories
                                                    };
                                                  } else {
                                                    let index = rewardList.findIndex(
                                                      el => el.id === reward.id
                                                    );
                                                    if (index === -1) {
                                                      rewardList.push({
                                                        ...reward,
                                                        tagsToRender: [
                                                          dynamicRewardCategories.title
                                                        ]
                                                      });
                                                    } else {
                                                      rewardList[index] = {
                                                        ...reward,
                                                        tagsToRender: [
                                                          dynamicRewardCategories.title
                                                        ]
                                                      };
                                                    }
                                                    dynamicRewardCategories.rewardList = [
                                                      ...rewardList
                                                    ];
                                                    rewardArray[
                                                      dynamicRewardCategories.idToCompare
                                                    ] = {
                                                      ...dynamicRewardCategories
                                                    };
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        })
                                      );
                                      primaryMenu.rewardCatalogues = rewardArray;
                                    }
                                  )
                                );
                                // update menu value
                                let menuIndex = primaryMenusArray.findIndex(
                                  el => el.id === primaryMenu.id
                                );
                                if (menuIndex === -1) {
                                  primaryMenusArray.push(primaryMenu);
                                  primaryMenusArray.sort((a, b) =>
                                    a.order > b.order
                                      ? 1
                                      : b.order > a.order
                                      ? -1
                                      : 0
                                  );
                                  if (
                                    primaryMenusArray.length ===
                                    tempArray.length
                                  ) {
                                    // console.log(primaryMenusArray)
                                    commit(
                                      "setPrimaryMenus",
                                      primaryMenusArray
                                    );
                                  }
                                } else {
                                  primaryMenusArray[menuIndex] = primaryMenu;
                                  if (
                                    primaryMenusArray.length ===
                                    tempArray.length
                                  ) {
                                    // console.log(primaryMenusArray)
                                    commit(
                                      "setPrimaryMenus",
                                      primaryMenusArray
                                    );
                                  }
                                }
                              },
                              error => {
                                console.log(error);
                                if (rewardCategoryUnsubscribe) {
                                  rewardCategoryUnsubscribe();
                                }
                              }
                            );
                        },
                        error => {
                          console.log(error);
                          if (rewardUnsubscribe) {
                            rewardUnsubscribe();
                          }
                        }
                      );
                  },
                  error => {
                    console.log(error);
                    if (rewardCatalogueUnsubscribe) {
                      rewardCatalogueUnsubscribe();
                    }
                  }
                );
              // reward logic ends here***********************************************************************************************************
            } else if (primaryMenu.contentType === "Custom Page") {
              const pageUnsubscribe = dbRef
                .collection("pages")
                .doc(primaryMenu.content)
                .onSnapshot(
                  pageQuerySnapshot => {
                    let pageData = pageQuerySnapshot.data();
                    let memberTagExist = false;
                    if (pageData.memberTags.length === 0) {
                      memberTagExist = true;
                    } else {
                      memberTagExist = pageData.memberTags.some(tag => {
                        return user.tags.includes(tag);
                      });
                    }
                    let companyTagExist = false;
                    if (pageData.companyTags.length === 0) {
                      companyTagExist = true;
                    } else {
                      companyTagExist = pageData.companyTags.some(tag => {
                        return user.companyTags.includes(tag);
                      });
                    }
                    const item =
                      memberTagExist && companyTagExist
                        ? { ...primaryMenu }
                        : { ...primaryMenu, hidden: true };
                    const index = primaryMenusArray.findIndex(
                      menu => menu.id === item.id
                    );
                    if (index === -1) {
                      primaryMenusArray.push(item);
                    } else {
                      primaryMenusArray[index] = item;
                    }
                    primaryMenusArray.sort((a, b) =>
                      a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                    );
                    if (primaryMenusArray.length === tempArray.length) {
                      // console.log(primaryMenusArray)
                      commit("setPrimaryMenus", primaryMenusArray);
                    }
                  },
                  error => {
                    console.log(error);
                    if (pageUnsubscribe) {
                      pageUnsubscribe();
                    }
                  }
                );
            } else {
              // update menu value
              primaryMenusArray.push(primaryMenu);
              primaryMenusArray.sort((a, b) =>
                a.order > b.order ? 1 : b.order > a.order ? -1 : 0
              );
              if (primaryMenusArray.length === tempArray.length) {
                // console.log(primaryMenusArray)
                commit("setPrimaryMenus", primaryMenusArray);
              }
            }
          });
        },
        error => {
          console.log(error);
          if (menuUnsubscribe) {
            menuUnsubscribe();
          }
        }
      );
  },

  async getSecondaryMenus({ commit, getters }, user) {
    // console.log("sec menu run");
    const dbRef = db.collection("programs").doc(getters.programId);
    const menuUnsubscribe = dbRef
      .collection("menus")
      .where("status", "==", "Active")
      .onSnapshot(
        querySnapshot => {
          let secondaryMenus = [];
          querySnapshot.forEach(doc => {
            let menu = {
              ...doc.data(),
              id: doc.id
            };
            if (menu.contentType === "Custom Page") {
              const pageUnsubscribe = dbRef
                .collection("pages")
                .doc(menu.content)
                .onSnapshot(
                  pageQuerySnapshot => {
                    const pageData = pageQuerySnapshot.data();
                    if (pageData) {
                      let memberTagExist = false;
                      if (pageData.memberTags.length === 0) {
                        memberTagExist = true;
                      } else {
                        memberTagExist = pageData.memberTags.some(tag => {
                          return user.tags.includes(tag);
                        });
                      }
                      let companyTagExist = false;
                      if (pageData.companyTags.length === 0) {
                        companyTagExist = true;
                      } else {
                        companyTagExist = pageData.companyTags.some(tag => {
                          return user.companyTags.includes(tag);
                        });
                      }
                      if (memberTagExist && companyTagExist) {
                        const index = secondaryMenus.findIndex(
                          item => item.id === menu.id
                        );
                        if (index === -1) {
                          // add to secondary menu
                          secondaryMenus.push(menu);
                        } else {
                          secondaryMenus[index] = menu;
                        }
                        secondaryMenus.sort((a, b) =>
                          a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                        );
                        commit("setSecondaryMenus", [...secondaryMenus]);
                      } else {
                        // remove from secondary menu
                        const index = secondaryMenus.findIndex(
                          item => item.content === menu.content
                        );
                        if (index !== -1) {
                          secondaryMenus.splice(index, 1);
                          secondaryMenus.sort((a, b) =>
                            a.order > b.order ? 1 : b.order > a.order ? -1 : 0
                          );
                        }

                        commit("setSecondaryMenus", [...secondaryMenus]);
                      }
                    }
                  },
                  error => {
                    console.log(error);
                    if (pageUnsubscribe) {
                      pageUnsubscribe();
                    }
                  }
                );
            } else if (menu.contentType === "Blog") {
              let blogIdToCompare = menu.content;
              const blogUnsubscribe = dbRef
                .collection("blogs")
                .doc(menu.content) // this is where tags logic will aplly
                .onSnapshot(
                  querySnapshot => {
                    let eachBlog = querySnapshot.data();
                    let blogMemberTagExist = false;
                    if (eachBlog.memberTags.length === 0) {
                      blogMemberTagExist = true;
                    } else {
                      blogMemberTagExist = eachBlog.memberTags.some(tag => {
                        return user.tags.includes(tag);
                      });
                    }
                    let blogCompanyTagExist = false;
                    if (eachBlog.companyTags.length === 0) {
                      blogCompanyTagExist = true;
                    } else {
                      blogCompanyTagExist = eachBlog.companyTags.some(tag => {
                        return user.companyTags.includes(tag);
                      });
                    }
                    if (blogMemberTagExist && blogCompanyTagExist) {
                      menu.hidden = false;
                    } else {
                      menu.hidden = true;
                    }
                    menu.screenTitle = eachBlog.title;
                    menu.blogs = {};
                    const articleUnsubscribe = dbRef
                      .collection("articles")
                      .orderBy("updated", "desc")
                      .where("blog", "==", blogIdToCompare)
                      .where("status", "==", "Active")
                      .onSnapshot(
                        async articleQuerySnapshot => {
                          let articleArray = {};
                          // console.log(articleQuerySnapshot, 'menu')
                          let dynamicCategoriesArray = [];
                          const categoryUnsubscribe = dbRef
                            .collection("categorys")
                            .onSnapshot(
                              async categorySnapshot => {
                                try {
                                  let temp = [];
                                  categorySnapshot.forEach(doc => {
                                    temp.push({
                                      ...doc.data(),
                                      idToCompare: doc.id
                                    });
                                  });
                                  dynamicCategoriesArray = await Promise.all(
                                    temp.map(async doc => {
                                      // run for each doc
                                      let dynamicCategories = doc;
                                      const creater =
                                        dynamicCategories.updatedBy ||
                                        dynamicCategories.createdBy;
                                      const querySnapshot = await db
                                        .collection("admins")
                                        .doc(creater)
                                        .get();
                                      let adminObj = querySnapshot.data();
                                      dynamicCategories.author = adminObj
                                        ? adminObj.name
                                        : "";
                                      return dynamicCategories;
                                    })
                                  );
                                } catch (error) {
                                  console.log(error);
                                  dynamicCategoriesArray = [];
                                }
                                await Promise.all(
                                  dynamicCategoriesArray.map(
                                    async dynamicCategories => {
                                      let articleList = [];
                                      let id = 0;
                                      let articleData = [];
                                      articleQuerySnapshot.forEach(doc => {
                                        articleData.push({
                                          ...doc.data(),
                                          id: doc.id
                                        });
                                      });
                                      await Promise.all(
                                        articleData.map(async article => {
                                          let memberTagExist = false;
                                          if (article.memberTags.length === 0) {
                                            memberTagExist = true;
                                          } else {
                                            memberTagExist = article.memberTags.some(
                                              tag => {
                                                return user.tags.includes(tag);
                                              }
                                            );
                                          }
                                          let companyTagExist = false;
                                          if (
                                            article.companyTags.length === 0
                                          ) {
                                            companyTagExist = true;
                                          } else {
                                            companyTagExist = article.companyTags.some(
                                              tag => {
                                                return user.companyTags.includes(
                                                  tag
                                                );
                                              }
                                            );
                                          }
                                          if (
                                            article.categorys &&
                                            article.status === "Active" &&
                                            companyTagExist &&
                                            memberTagExist
                                          ) {
                                            const creater =
                                              article.updatedBy ||
                                              article.createdBy;
                                            const querySnapshot = await db
                                              .collection("admins")
                                              .doc(creater)
                                              .get();
                                            let adminObj = querySnapshot.data();
                                            article.author = adminObj
                                              ? adminObj.name
                                              : "";
                                            article.categorys.map(
                                              eachCategory => {
                                                if (
                                                  eachCategory ===
                                                  dynamicCategories.idToCompare
                                                ) {
                                                  dynamicCategories.id = id;
                                                  id++;
                                                  if (
                                                    articleArray.hasOwnProperty(
                                                      dynamicCategories.idToCompare
                                                    )
                                                  ) {
                                                    let index = articleArray[
                                                      dynamicCategories
                                                        .idToCompare
                                                    ].articleList.findIndex(
                                                      el => el.id === article.id
                                                    );
                                                    if (index === -1) {
                                                      articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ].articleList.push({
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      });
                                                      articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ].articleList.sort(
                                                        (a, b) => {
                                                          return (
                                                            new Date(
                                                              b.updated
                                                            ) -
                                                            new Date(a.updated)
                                                          );
                                                        }
                                                      );
                                                    } else {
                                                      articleArray[
                                                        dynamicCategories.idToCompare
                                                      ].articleList[index] = {
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      };
                                                    }
                                                    articleArray[
                                                      dynamicCategories.idToCompare
                                                    ] = {
                                                      ...articleArray[
                                                        dynamicCategories
                                                          .idToCompare
                                                      ],
                                                      ...dynamicCategories
                                                    };
                                                  } else {
                                                    let index = articleList.findIndex(
                                                      el => el.id === article.id
                                                    );
                                                    if (index === -1) {
                                                      articleList.push({
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      });
                                                    } else {
                                                      articleList[index] = {
                                                        ...article,
                                                        tagsToRender: [
                                                          dynamicCategories.title
                                                        ]
                                                      };
                                                    }
                                                    dynamicCategories.articleList = [
                                                      ...articleList
                                                    ];
                                                    articleArray[
                                                      dynamicCategories.idToCompare
                                                    ] = {
                                                      ...dynamicCategories
                                                    };
                                                  }
                                                }
                                              }
                                            );
                                          }
                                        })
                                      );
                                      menu.blogs = articleArray;
                                    }
                                  )
                                );
                                // update menu value
                                let menuIndex = secondaryMenus.findIndex(
                                  el => el.id === menu.id
                                );
                                if (menuIndex === -1) {
                                  secondaryMenus.push(menu);
                                  secondaryMenus.sort((a, b) =>
                                    a.order > b.order
                                      ? 1
                                      : b.order > a.order
                                      ? -1
                                      : 0
                                  );
                                  console.log(secondaryMenus);
                                  commit("setSecondaryMenus", [
                                    ...secondaryMenus
                                  ]);
                                } else {
                                  secondaryMenus[menuIndex] = secondaryMenus;
                                  console.log(secondaryMenus);
                                  commit("setSecondaryMenus", [
                                    ...secondaryMenus
                                  ]);
                                }
                              },
                              error => {
                                console.log(error);
                                if (categoryUnsubscribe) {
                                  categoryUnsubscribe();
                                }
                              }
                            );
                        },
                        error => {
                          console.log(
                            error.nativeErrorCode,
                            error.nativeErrorMessage,
                            error.code
                          );
                          if (articleUnsubscribe) {
                            articleUnsubscribe();
                          }
                        }
                      );
                  },
                  error => {
                    console.log(error);
                    if (blogUnsubscribe) {
                      blogUnsubscribe();
                    }
                  }
                );
            }
          });
        },
        error => {
          console.log(error);
          if (menuUnsubscribe) {
            menuUnsubscribe();
          }
        }
      );
  },

  async getDatabucketData({ commit, getters }, databucketId) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    const actualDataRef = databucketRef.collection("actualData");

    const apiCalls = [actualDataRef.orderBy("created").get()];

    let actualDataSnapshot;

    try {
      [actualDataSnapshot] = await Promise.all(apiCalls);
    } catch (e) {
      throw "Error occured when loading data bucket";
    }

    const actualData = [];
    actualDataSnapshot.forEach(item => {
      const data = item.data();
      actualData.push({
        id: item.id,
        created: data.created.toDate(),
        ...data.data
      });
    });
    commit("setDatabucketData", actualData);
  }
};

const mutations = {
  setDatabucketData(state, payload) {
    state.databucketData = payload;
  },
  setProgressData(state, payload) {
    state.progressData = payload;
  },
  setLeaderBoardData(state, payload) {
    state.leaderBoardData = payload;
  },
  setTableData(state, payload) {
    state.tableData = payload;
  },
  setPromotionList(state, payload) {
    state.promotionList = payload;
  },
  setRewardList(state, payload) {
    state.rewardList = payload;
  },
  setPrimaryMenus(state, payload) {
    state.primaryMenus = payload;
  },
  setSecondaryMenus(state, payload) {
    state.secondaryMenus = payload;
  }
};

const getters = {
  databucketData(state) {
    return state.databucketData;
  },
  progressData(state) {
    return state.progressData;
  },
  leaderBoardData(state) {
    return state.leaderBoardData;
  },
  tableData(state) {
    return state.tableData;
  },
  promotionList(state) {
    return state.promotionList;
  },
  rewardList(state) {
    return state.rewardList;
  },
  primaryMenus(state) {
    return state.primaryMenus;
  },
  secondaryMenus(state) {
    return state.secondaryMenus;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
