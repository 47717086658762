import { db } from '@/firebase'

const state = {
  hasWebThemePendingUpdates: false,
  selectedPageTemplate: '',
  newComponent: null,
  designDrawer: false,
  siteDrawer: false,
  openDesignDrawer: false,
  editingField: {},
  editingComponent: {},
  selectedTheme: {},
  side: 'left'
}

const actions = {
  setSelectedPageTemplate ({commit}, payload) {
    commit('setSelectedPageTemplate', payload)
  },
}

const mutations = {
  setSelectedPageTemplate(state, payload) {
    state.selectedPageTemplate = payload
  },
}

const getters = {
  selectedPageTemplate (state) {
    return state.selectedPageTemplate
  },
  grid (state) {
    return {
      block: 'grid-off',
      outer: 'grid-off',
      inner: 'grid-off'
    }
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
