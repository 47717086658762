import Vue from "vue";
import "./plugins/meta";
import vuetify from "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import { store } from "./store";
import i18n from "@/plugins/i18n";
import "vuetify/dist/vuetify.min.css";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import StatusIcon from "@/components/Shared/StatusIcon";
import vueCountryRegionSelect from "vue-country-region-select";
import VeeValidate from "vee-validate";
import VuePlyr from "vue-plyr";
import "vue-plyr/dist/vue-plyr.css";

Vue.use(vueCountryRegionSelect);
Vue.use(VeeValidate);
Vue.use(VuePlyr, {
  plyr: {}
});

Vue.config.productionTip = false;

Vue.use(PerfectScrollbar);
Vue.component("status-icon", StatusIcon);

// import vue2Dropzone from 'vue2-dropzone'
// import 'vue2-dropzone/dist/vue2Dropzone.min.css'
// Vue.component('vue-dropzone', vue2Dropzone)

//Filters
import DateFilter from "@/filters/date";
import NumberFilter from "@/filters/number";
import LongDateFilter from "@/filters/longdate";
import TimeFilter from "@/filters/time";
import TimeStampFilter from "@/filters/timestamp";
import ShortDate from "@/filters/shortdate";
import Truncate from "@/filters/truncate";
import Number from "@/filters/number";
import ConvertTimestamp from "@/filters/convertTimestamp";

Vue.filter("time", TimeFilter);
Vue.filter("timestamp", TimeStampFilter);
Vue.filter("date", DateFilter);
Vue.filter("number", NumberFilter);
Vue.filter("longdate", LongDateFilter);
Vue.filter("shortdate", ShortDate);
Vue.filter("truncate", Truncate);
Vue.filter("number", Number);
Vue.filter("convertTimestamp", ConvertTimestamp); // handles raw Firebase timestamp

new Vue({
  i18n,
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
