import { db } from '@/firebase'

const state = {
  offerList: []
}

const actions = {
  async getOfferList ({ commit }, user) {
    const dbRef = db.collection('programs').doc(this.getters.programId)
    const offerUnsubscribe = dbRef.collection('offers')
    .orderBy('order')
    .onSnapshot((querySnapshot) => {
      let offerList = []
      querySnapshot.forEach((doc) => {
        let resultData = {
          ...doc.data(),
          offerId: doc.id,
          cardType: 'offer'
        }
        if (resultData.status === 'Active' || resultData.status === 'active') {
          let memberTagExist = false
          if (resultData.memberTags.length === 0) {
            memberTagExist = true
          } else {
            memberTagExist = resultData.memberTags.some(tag => {
              return user.tags.includes(tag)
            })
          }
          let companyTagExist = false
          if (resultData.companyTags.length === 0) {
            companyTagExist = true
          } else {
            companyTagExist = resultData.companyTags.some(tag => {
              return user.companyTags.includes(tag)
            })
          }
          if (memberTagExist && companyTagExist) {
            offerList.push({
              ...resultData,
              created: resultData.created && resultData.created.toDate(),
              updated: resultData.updated && resultData.updated.toDate()
            })
          }
        }
      })
      // console.log(offerList)
      commit('setOfferList', offerList)
    }, (error) => {
      console.log(error)
      if (offerUnsubscribe) {
        offerUnsubscribe()
      }
    })
  },

  async submitEntry ({ commit }, payload) {
    const dbRef = db.collection('programs').doc(this.getters.programId)
    var doc = await dbRef.collection('offers').doc(payload.offerId).get()
    const offer = { ...doc.data() }
    const entries = offer.entries || []
    const newEntries = [...entries, payload.data]
    await dbRef.collection('offers').doc(payload.offerId).update({
      entries: newEntries
    })
  },

  async delEntry ({ commit }, payload) {
    const dbRef = db.collection('programs').doc(this.getters.programId)
    var doc = await dbRef.collection('offers').doc(payload.offerId).get()
    const offer = { ...doc.data() }
    const entries = offer.entries || []
    const newEntries = entries.filter(entry => entry.id !== payload.entry.id)
    await dbRef.collection('offers').doc(payload.offerId).update({
      entries: newEntries
    })
  }
}

const mutations = {
  setOfferList (state, payload) {
    state.offerList = payload
  }
}

const getters = {
  offerList (state) {
    return state.offerList
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
