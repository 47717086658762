/* eslint-disable no-console */
import _ from "lodash";
import { db, timestamp } from "@/firebase";
import firebase from "firebase/app";
import { getNum } from "@/utils";

const state = {
  databucket: null,
  csvTargetData: [],
  newCsvTargetFileName: "",
  csvActualData: [],
  newCsvActualFileName: "",
  targetData: null,
  actualData: [],
  loadingDatabucket: false,
  changes: [],
  databucketRedeemableTransactions: [],
  loadingDatabucketRedeemableTransactions: false,
  revokeRedeemablePointsProcessing: false,
  lastRuleDeleted: false
};

const actions = {
  async loadDatabucket({ commit, getters }, databucketId) {
    commit("setLoadingDatabucket", true);
    commit("setDatabucket", {});

    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    const actualDataRef = databucketRef.collection("actualData");

    const targetDataRef = databucketRef.collection("targetData");

    const apiCalls = [
      databucketRef.get(),
      actualDataRef.orderBy("created").get(),
      targetDataRef.doc("latest").get()
    ];

    let databucketSnapshot;
    let actualDataSnapshot;
    let targetDataSnapshot;

    try {
      [
        databucketSnapshot,
        actualDataSnapshot,
        targetDataSnapshot
      ] = await Promise.all(apiCalls);
    } catch (e) {
      throw "Error occured when loading data bucket";
    }

    const databucket = {
      id: databucketSnapshot.id,
      ...databucketSnapshot.data(),
      created: databucketSnapshot.data().created.toDate(),
      updated: databucketSnapshot.data().updated.toDate()
    };

    const actualData = [];
    actualDataSnapshot.forEach(item => {
      const data = item.data();
      actualData.push({
        id: item.id,
        ...data,
        created: data.created.toDate()
      });
    });

    const updated = targetDataSnapshot.data()
      ? targetDataSnapshot.data().updated.toDate()
      : "";
    const targetData = {
      ...targetDataSnapshot.data(),
      updated: updated
    };

    commit("setDatabucket", databucket);
    commit("setActualData", actualData);
    commit("setTargetData", targetData);
    commit("setLoadingDatabucket", false);
  },

  async createTargetCode({ commit, getters, dispatch }, payload) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);
    try {
      databucketRef.update({
        targetCodes: firebase.firestore.FieldValue.arrayUnion(payload)
      });
    } catch (e) {
      console.log(e);
      throw "Error occured when adding a new target code";
    }

    const newTargetCodes = [...getters.databucket.targetCodes, payload];

    commit("patchDatabucket", { targetCodes: newTargetCodes });
    dispatch("setSnackbar", "Category Key added.");
  },

  async deleteTargetCode({ commit, getters, dispatch }, payload) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);

    try {
      databucketRef.update({
        targetCodes: firebase.firestore.FieldValue.arrayRemove(payload)
      });
    } catch (e) {
      throw "Error occured when adding a new target code";
    }

    const newTargetCodes = getters.databucket.targetCodes.filter(
      item => item !== payload
    );

    commit("patchDatabucket", { targetCodes: newTargetCodes });
    dispatch("setSnackbar", "Target code deleted.");
  },

  async reorderTargetOrders({ getters, commit }, payload) {
    // Note: Make changes locally to show user visual changes immediately
    commit("patchDatabucket", { targetCodes: payload });

    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);

    try {
      await databucketRef.update({ targetCodes: payload });
    } catch (e) {
      throw "Error occured when reordering target codes.";
    }
  },

  async updateTargetMapping({ getters, commit, dispatch }, payload) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);

    try {
      await databucketRef.update({ targetMapping: payload });
    } catch (e) {
      throw "Error occured when updating target mapping";
    }
    commit("patchDatabucket", { targetMapping: payload });
    dispatch("setSnackbar", "Target Map Updated");
  },

  async updateActualMapping({ getters, commit, dispatch }, payload) {
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);

    try {
      await databucketRef.update({ actualMapping: payload });
    } catch (e) {
      throw "Error occured when updating actual mapping";
    }

    commit("patchDatabucket", { actualMapping: payload });
    dispatch("setSnackbar", "Actual mapping updated");
  },

  async updateTargetData({ getters, commit, dispatch }, payload) {
    const targetDataRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id)
      .collection("targetData");

    const date = new Date();
    try {
      await targetDataRef.doc("latest").set({
        ...payload,
        updated: date
      });
    } catch (e) {
      throw "Error occured when importing data.";
    }

    commit("setTargetData", { ...payload, updated: date });
    dispatch("setSnackbar", "Target data imported successfully");
  },

  async createActualData({ getters, commit, dispatch }, payload) {
    const actualDataRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id)
      .collection("actualData");

    try {
      await actualDataRef.add({ ...payload, created: timestamp });
    } catch (e) {
      throw "Error occured when importing data.";
    }

    commit("createActualData", { ...payload, created: new Date() });
    dispatch("setSnackbar", "Data imported successfully");
  },

  async deleteActualData({ getters, commit, dispatch }, id) {
    const actualDataRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id)
      .collection("actualData");

    try {
      await actualDataRef.doc(id).delete();
    } catch (e) {
      throw "Error occured when reversing file";
    }

    commit("deleteActualData", id);
    dispatch("setSnackbar", "File was reversed");
  },

  async updateResult({ getters, dispatch, state }) {
    const {
      databucketPreviewColumns,
      databucketPointsFormula,
      targetCodes,
      runningTotalIndex,
      databucketColumnData,
      databucketTotalData
    } = getters;

    const { databucket } = state;

    const databucketRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(getters.databucket.id);

    const resulstRef = databucketRef.collection("results");
    const visibleColumns = databucketPreviewColumns.filter(item => item.show);
    const csvHeaders = [
      "Account Id",
      "Target Code",
      ...visibleColumns.map(item => item.label)
    ];

    const columnResult = databucketColumnData.reduce(
      (result, participant) => {
        const { data } = participant;
        const newData = [];
        targetCodes.forEach(targetCode => {
          const row = [participant.accountKey, targetCode];
          visibleColumns.forEach(header => {
            row.push(data[`${targetCode}-${header.column}`]);
          });
          newData.push(row);
        });

        return [...result, ...newData];
      },
      [csvHeaders]
    );

    const totalResult = databucketTotalData.reduce(
      (result, participant) => {
        const { data } = participant;
        const newData = [participant.accountKey, "Total"];
        visibleColumns.forEach(header => {
          newData.push(data[header.column]);
        });
        return [...result, newData];
      },
      [csvHeaders]
    );

    const databucketCall = databucketRef.update({
      previewColumns: databucketPreviewColumns,
      pointsFormula: databucketPointsFormula,
      status: databucket.status,
      pointsRedeemable: databucket.pointsRedeemable,
      title: databucket.title,
      titleUppercase: databucket.titleUppercase,
      displayTitle: databucket.displayTitle,
      displayTitleUppercase: databucket.displayTitleUppercase
    });

    const runningTotalMappings =
      runningTotalIndex >= 0
        ? {
            column: "Target Code",
            row: targetCodes[runningTotalIndex]
          }
        : {};

    const columnDoc = {
      csvData: JSON.stringify(columnResult),
      csvName: "databucket.csv",
      runningTotalMappings,
      updated: timestamp
    };

    const totalDoc = {
      csvData: JSON.stringify(totalResult),
      csvName: "databucket.csv",
      updated: timestamp
    };

    const columnResultCall = resulstRef.doc("column").set(columnDoc);

    const totalResultCall = resulstRef.doc("total").set(totalDoc);

    try {
      await Promise.all([databucketCall, columnResultCall, totalResultCall]);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating result mapping";
    }

    // Issue redeemable points
    if (databucket.pointsRedeemable && databucketPointsFormula.length > 0) {
      try {
        const databucketId = getters.databucket.id;
        const databucketTitle = databucket.title;
        await dispatch("addRedeemablePointsFromDatabucket", {
          databucketId,
          totalResult,
          databucketTitle
        });
      } catch (e) {
        console.error(e);
        throw "Error occured when adding redeemable points to members";
      }
    } else {
      console.log("points are not redeemable from the databucket");
    }

    if (getters.lastRuleDeleted) {
      try {
        await dispatch("revokeRedeemablePointsByDatabucket");
      } catch (e) {
        console.error(e);
        throw "Error occured when adding revoking points from members";
      }
    } else {
      console.log("last rule not deleted");
    }

    return {
      columnDoc,
      totalDoc
    };
  },

  async deleteDatabucket({ getters, dispatch }, payload) {
    const databucketId = payload;
    const databucketRef = await db
      .collection("programs")
      .doc(getters.programId)
      .collection("databuckets")
      .doc(databucketId);

    const actualDataRef = await databucketRef
      .collection("actualData")
      .limit(1)
      .get();
    const targetDataRef = await databucketRef
      .collection("targetData")
      .limit(1)
      .get();
    const resultsDataRef = await databucketRef
      .collection("results")
      .limit(1)
      .get();

    const exists =
      actualDataRef.size + targetDataRef.size + resultsDataRef.size;
    if (exists > 0) {
      console.log("Cannot delete as data exists");
      dispatch("setSnackbar", "Cannot delete as data exists");
    } else {
      try {
        await databucketRef.delete();
      } catch (e) {
        console.log(e);
        throw "error when deleting the databucket";
      }
    }
  },

  loadingDatabucket({ commit }, payload) {
    commit("setLoadingDatabucket", payload);
  },
  setLastRuleDeleted({ commit }, payload) {
    commit("setLastRuleDeleted", payload);
  },
  updateChanges({ commit }, payload) {
    commit("setChanges", payload);
  },
  patchDatabucket({ commit }, payload) {
    commit("patchDatabucket", payload);
  },
  setCsvTargetData({ commit }, payload) {
    commit("setCsvTargetData", payload);
  },
  setNewCsvTargetFileName({ commit }, payload) {
    commit("setNewCsvTargetFileName", payload);
  },
  setCsvActualData({ commit }, payload) {
    commit("setCsvActualData", payload);
  },
  setNewCsvActualFileName({ commit }, payload) {
    commit("setNewCsvActualFileName", payload);
  },
  setDatabucketRedeemableTransactions({ commit }, payload) {
    commit("setDatabucketRedeemableTransactions", payload);
  },
  setLoadingDatabucketRedeemableTransactions({ commit }, payload) {
    commit("setLoadingDatabucketRedeemableTransactions", payload);
  },
  setRevokeRedeemablePointsProcessing({ commit }, payload) {
    commit("setRevokeRedeemablePointsProcessing", payload);
  }
};

const mutations = {
  setLastRuleDeleted(state, payload) {
    state.lastRuleDeleted = payload;
  },

  setDatabucket(state, payload) {
    state.databucket = payload;
  },

  setDatabucketRedeemableTransactions(state, payload) {
    state.databucketRedeemableTransactions = payload;
  },

  setLoadingDatabucketRedeemableTransactions(state, payload) {
    state.loadingDatabucketRedeemableTransactions = payload;
  },

  setRevokeRedeemablePointsProcessing(state, payload) {
    state.revokeRedeemablePointsProcessing = payload;
  },

  setLoadingDatabucket(state, payload) {
    state.loadingDatabucket = payload;
  },

  setActualData(state, payload) {
    state.actualData = payload;
  },

  setTargetData(state, payload) {
    state.targetData = payload;
  },

  setCsvTargetData(state, payload) {
    state.csvTargetData = payload;
  },

  setNewCsvTargetFileName(state, payload) {
    state.newCsvTargetFileName = payload;
  },

  setCsvActualData(state, payload) {
    state.csvActualData = payload;
  },

  setNewCsvActualFileName(state, payload) {
    state.newCsvActualFileName = payload;
  },

  createActualData(state, payload) {
    state.actualData = [...state.actualData, payload];
  },

  deleteActualData(state, id) {
    state.actualData = state.actualData.filter(item => item.id !== id);
  },

  patchDatabucket(state, payload) {
    state.databucket = {
      ...state.databucket,
      ...payload
    };
  },

  setChanges(state, payload) {
    state.changes = payload;
  }
};

const getters = {
  lastRuleDeleted(state) {
    return state.lastRuleDeleted;
  },

  databucket(state) {
    return state.databucket;
  },

  changes(state) {
    return state.changes;
  },

  loadingDatabucketRedeemableTransactions(getters) {
    return getters.databucket.loadingDatabucketRedeemableTransactions;
  },

  databucketRedeemableTransactions(getters) {
    return getters.databucket.databucketRedeemableTransactions;
  },

  revokeRedeemablePointsProcessing(getters) {
    return getters.revokeRedeemablePointsProcessing;
  },

  loadingDatabucket(getters) {
    return getters.databucket.loadingDatabucket;
  },

  csvTargetData(getters) {
    return getters.databucket.csvTargetData;
  },

  newCsvTargetFileName(getters) {
    return getters.databucket.newCsvTargetFileName;
  },

  csvActualData(getters) {
    return getters.databucket.csvActualData;
  },

  newCsvActualFileName(getters) {
    return getters.databucket.newCsvActualFileName;
  },

  databucketsOffers(state, getters) {
    return getters.databucket.offers || [];
  },

  databucketsQuizzes(state, getters) {
    return getters.databucket.quizzes || [];
  },

  databucketEntityMap(state, getters) {
    return getters.databucket.participantType === "member"
      ? getters.memberAccountKeysMap
      : getters.companyAccountKeysMap;
  },

  targetCodes(state, getters) {
    return getters.databucket.targetCodes || [];
  },

  targetConsumedTargetCodes(state) {
    if (!state.targetData) {
      return [];
    }
    return state.targetData.consumedTargetCodes || [];
  },

  actualConsumedTargetCodes(state) {
    const consumedTargetCodes = state.actualData.reduce((result, eachData) => {
      return [...result, ...eachData.consumedTargetCodes];
    }, []);

    return consumedTargetCodes;
  },

  allConsumedTargetCodes(state, getters) {
    return getters.targetConsumedTargetCodes.concat(
      getters.actualConsumedTargetCodes
    );
  },

  runningTotalIndex(state, getters) {
    const { actualConsumedTargetCodes } = getters;

    if (!actualConsumedTargetCodes.length) {
      return -1;
    }

    let runningTotalIndex;
    for (let i = 0; i < getters.targetCodes.length; i += 1) {
      const tc = getters.targetCodes[i];
      const found = actualConsumedTargetCodes.find(item => item == tc);
      if (found) {
        runningTotalIndex = i;
      }
    }

    return runningTotalIndex;
  },

  mergedActualData(state) {
    return state.actualData.reduce((aggr, eachData) => {
      const { consumedTargetCodes } = eachData;
      return eachData.data.reduce((subAggr, row) => {
        const idx = subAggr.findIndex(
          item => item["Account Key"] === row["Account Key"]
        );
        if (idx === -1) {
          return [...subAggr, row];
        }

        const mergedRow = {
          ...subAggr[idx]
        };

        consumedTargetCodes.forEach(targetCode => {
          const newVal = row[targetCode] || "0";
          const oldVal = mergedRow[targetCode] || "0";

          mergedRow[targetCode] = getNum(newVal) + getNum(oldVal);
        });

        return [...subAggr.slice(0, idx), mergedRow, ...subAggr.slice(idx + 1)];
      }, aggr);
    }, []);
  },

  mergedTargetData(state) {
    return (state.targetData || {}).data || [];
  },

  databucketParticipants(state, getters) {
    const allAccountKeyValues = getters.mergedTargetData.map(
      item => item["Account Key"]
    );
    const uniqAccountKeyValues = _.uniq(allAccountKeyValues);

    return uniqAccountKeyValues.reduce((result, accountKey) => {
      if (!getters.databucketEntityMap[accountKey]) {
        return result;
      }

      const label = getters.databucketEntityMap[accountKey].title;

      return [
        ...result,
        {
          label,
          value: accountKey,
          ...getters.databucketEntityMap[accountKey]
        }
      ];
    }, []);
  },

  databucketPointsFormula(state) {
    return state.databucket.pointsFormula || [];
  },

  databucketPreviewColumns(state) {
    if (state.databucket.previewColumns) {
      const offerExist =
        state.databucket.previewColumns.findIndex(
          el => el.column == "offerPoints"
        ) !== -1;
      if (offerExist) {
        return state.databucket.previewColumns;
      }
      return state.databucket.previewColumns.concat([
        {
          column: "offerPoints",
          label: "Claim Points",
          calculated: true,
          show: true
        },
        {
          column: "quizPoints",
          label: "Quiz Points",
          calculated: true,
          show: true
        },
        {
          column: "totalPoints",
          label: "Total Points",
          calculated: true,
          show: true
        }
      ]);
    }
    return [
      { column: "actual", label: "Actual", show: true },
      { column: "target", label: "Target", show: true },
      { column: "diff", label: "Difference", calculated: true, show: true },
      { column: "percent", label: "Percentage", calculated: true, show: true },
      {
        column: "points",
        label: "Target Points",
        calculated: true,
        show: true
      },
      {
        column: "offerPoints",
        label: "Claim Points",
        calculated: true,
        show: true
      },
      {
        column: "quizPoints",
        label: "Quiz Points",
        calculated: true,
        show: true
      },
      {
        column: "totalPoints",
        label: "Total Points",
        calculated: true,
        show: true
      }
    ];
  },

  databucketColumnData(state, getters) {
    const {
      targetCodes,
      runningTotalIndex,
      databucketPointsFormula,
      databucketParticipants,
      mergedTargetData,
      mergedActualData,
      databucketPreviewColumns,
      databucketsOffers,
      databucketsQuizzes,
      databucket
    } = getters;

    const columnData = databucketParticipants.map(participant => {
      const selectedTargetData =
        mergedTargetData.find(
          item => item["Account Key"] === participant.value
        ) || {};
      const selectedActualData =
        mergedActualData.find(
          item => item["Account Key"] === participant.value
        ) || {};

      const data = {};

      targetCodes.forEach((targetCode, targetCodeIdx) => {
        databucketPreviewColumns.forEach(mapping => {
          const { column } = mapping;
          const cellKey = `${targetCode}-${column}`;
          let cellVal = "";

          const targetVal = getNum(selectedTargetData[targetCode]);
          const actualVal = getNum(selectedActualData[targetCode]);
          const diffVal = actualVal - targetVal;
          const percentVal = (actualVal / targetVal) * 100;
          let points = 0;

          if (column === "target") {
            cellVal = targetVal;
          } else if (column === "actual") {
            cellVal = actualVal;
          } else if (column === "diff") {
            if (targetCodeIdx <= runningTotalIndex) {
              cellVal = diffVal;
            }
          } else if (column === "percent") {
            if (targetCodeIdx <= runningTotalIndex) {
              if (percentVal === Infinity) {
                cellVal = 0;
              } else {
                cellVal = percentVal;
              }
            }
          } else if (column === "points") {
            if (targetCodeIdx <= runningTotalIndex) {
              for (let i = 0; i < databucketPointsFormula.length; i += 1) {
                // redeemable points based on target acheivement
                const condition = databucketPointsFormula[i];

                // prepare conditional statement
                const fixedStatement = condition.statement
                  .replace(/__actual/gi, actualVal)
                  .replace(/__target/gi, targetVal)
                  .replace(/__percent/gi, percentVal)
                  .replace(/__diff/gi, diffVal);

                // evaluate statement to find if conditions are met, returns true or false
                const met = Function("return" + fixedStatement)();

                // prepare dynamic points value
                const dymanicStatement = condition.dynamicPoints
                  .replace(/__actual/gi, actualVal)
                  .replace(/__target/gi, targetVal)
                  .replace(/__percent/gi, percentVal)
                  .replace(/__diff/gi, diffVal);

                // evaluate statement to see what points are to be awarded by the dynamic calculation
                const dymanicPointsEval = condition.selectedVariable
                  ? Function("return" + dymanicStatement)()
                  : 0;

                const dynamicPoints = condition.selectedVariable
                  ? parseFloat(dymanicPointsEval)
                  : 0; // number

                const fixedPoints = condition.selectedFixed
                  ? parseFloat(condition.result)
                  : 0; // number

                if (met) {
                  if (targetVal === 0 || null) {
                    points = 0;
                  } else {
                    points = fixedPoints + dynamicPoints;
                  }
                  break;
                }
              }
              cellVal = Math.floor(points);
            }
          } else if (column === "offerPoints") {
            // if (targetCodeIdx <= runningTotalIndex) {
            let offerPoints = 0;
            if (databucket.participantType === "company") {
              const targetOffers = databucketsOffers.filter(
                el =>
                  el.targetCode === targetCode && el.company === participant.id
              );
              offerPoints = targetOffers.reduce((acc, offer) => {
                return acc + +offer.points;
              }, 0);
            } else {
              const targetOffers = databucketsOffers.filter(
                el =>
                  el.targetCode === targetCode && el.member === participant.id
              );
              offerPoints = targetOffers.reduce((acc, offer) => {
                return acc + +offer.points;
              }, 0);
            }

            cellVal = offerPoints == 0 ? 0 : Math.floor(offerPoints);
            // }
          } else if (column === "quizPoints") {
            // if (targetCodeIdx <= runningTotalIndex) {
            let quizPoints = 0;
            if (databucket.participantType === "company") {
              const targetQuizzes = databucketsQuizzes.filter(
                el =>
                  el.targetCode === targetCode && el.company === participant.id
              );
              quizPoints = targetQuizzes.reduce((acc, quiz) => {
                return acc + +quiz.points;
              }, 0);
            } else {
              const targetQuizzes = databucketsQuizzes.filter(
                el =>
                  el.targetCode === targetCode && el.member === participant.id
              );
              quizPoints = targetQuizzes.reduce((acc, quiz) => {
                return acc + +quiz.points;
              }, 0);
            }

            cellVal = quizPoints == 0 ? 0 : Math.floor(quizPoints);
            // }
          } else if (column === "totalPoints") {
            // if (targetCodeIdx <= runningTotalIndex) {
            for (let i = 0; i < databucketPointsFormula.length; i += 1) {
              const condition = databucketPointsFormula[i];

              // prepare conditional statement
              const fixedStatement = condition.statement
                .replace(/__actual/gi, actualVal)
                .replace(/__target/gi, targetVal)
                .replace(/__percent/gi, percentVal)
                .replace(/__diff/gi, diffVal);

              // evaluate statement to find if conditions are met, returns true or false
              const met = Function("return" + fixedStatement)();

              // prepare dynamic points value
              const dymanicStatement = condition.dynamicPoints
                .replace(/__actual/gi, actualVal)
                .replace(/__target/gi, targetVal)
                .replace(/__percent/gi, percentVal)
                .replace(/__diff/gi, diffVal);

              // evaluate statement to see what points are to be awarded by the dynamic calculation
              const dymanicPointsEval = condition.selectedVariable
                ? Function("return" + dymanicStatement)()
                : 0;

              const dynamicPoints = condition.selectedVariable
                ? parseFloat(dymanicPointsEval)
                : 0; // number

              const fixedPoints = condition.selectedFixed
                ? parseFloat(condition.result)
                : 0; // number

              if (met) {
                if (targetVal === 0 || null) {
                  points = 0;
                } else {
                  points = fixedPoints + dynamicPoints;
                }
                break;
              }
            }
            let offerPoints = 0;
            if (databucket.participantType === "company") {
              const targetOffers = databucketsOffers.filter(
                el =>
                  el.targetCode === targetCode && el.company === participant.id
              );
              offerPoints = targetOffers.reduce((acc, offer) => {
                return acc + +offer.points;
              }, 0);
            } else {
              const targetOffers = databucketsOffers.filter(
                el =>
                  el.targetCode === targetCode && el.member === participant.id
              );
              offerPoints = targetOffers.reduce((acc, offer) => {
                return acc + +offer.points;
              }, 0);
            }
            let quizPoints = 0;
            if (databucket.participantType === "company") {
              const targetQuizzes = databucketsQuizzes.filter(
                el =>
                  el.targetCode === targetCode && el.company === participant.id
              );
              quizPoints = targetQuizzes.reduce((acc, quiz) => {
                return acc + +quiz.points;
              }, 0);
            } else {
              const targetQuizzes = databucketsQuizzes.filter(
                el =>
                  el.targetCode === targetCode && el.member === participant.id
              );
              quizPoints = targetQuizzes.reduce((acc, quiz) => {
                return acc + +quiz.points;
              }, 0);
            }
            const value =
              Math.floor(points) +
              Math.floor(offerPoints) +
              Math.floor(quizPoints);
            cellVal = value == 0 ? 0 : value;
            // }
          }

          data[cellKey] = cellVal;
        });
      });

      return {
        accountKey: participant.value,
        data
      };
    });
    return columnData;
  },

  databucketTotalData(state, getters) {
    const {
      // runningTotalIndex,
      databucketColumnData,
      targetCodes,
      databucketPreviewColumns
    } = getters;

    // const targetCodesByRunningTotal = targetCodes.slice(0, runningTotalIndex + 1);
    const targetCodesByRunningTotal = [...targetCodes];

    return databucketColumnData.map(participant => {
      const columnData = participant.data;
      const {
        targetTotal,
        actualTotal,
        diffTotal,
        pointsTotal,
        offerPointsTotal,
        quizPointsTotal,
        totalPointsTotal
      } = targetCodesByRunningTotal.reduce(
        (result, targetCode) => {
          const target = +columnData[`${targetCode}-target`];
          const actual = +columnData[`${targetCode}-actual`];
          const diff = +columnData[`${targetCode}-diff`];
          const points = +columnData[`${targetCode}-points`];
          const offerPoints = +columnData[`${targetCode}-offerPoints`];
          const quizPoints = +columnData[`${targetCode}-quizPoints`];
          const totalPoints = +columnData[`${targetCode}-totalPoints`];
          return {
            targetTotal: result.targetTotal + target,
            actualTotal: result.actualTotal + actual,
            diffTotal: result.diffTotal + diff,
            pointsTotal: result.pointsTotal + points,
            offerPointsTotal: result.offerPointsTotal + offerPoints,
            quizPointsTotal: result.quizPointsTotal + quizPoints,
            totalPointsTotal: result.totalPointsTotal + totalPoints
          };
        },
        {
          targetTotal: 0,
          actualTotal: 0,
          diffTotal: 0,
          pointsTotal: 0,
          offerPointsTotal: 0,
          quizPointsTotal: 0,
          totalPointsTotal: 0
        }
      );

      const totalData = {};
      databucketPreviewColumns.forEach(mapping => {
        let val;
        if (mapping.column === "target") {
          val = targetTotal;
        } else if (mapping.column === "actual") {
          val = actualTotal;
        } else if (mapping.column === "diff") {
          val = diffTotal;
        } else if (mapping.column === "points") {
          val = pointsTotal;
        } else if (mapping.column === "percent") {
          val = (actualTotal / targetTotal) * 100;
        } else if (mapping.column === "offerPoints") {
          val = offerPointsTotal;
        } else if (mapping.column === "quizPoints") {
          val = quizPointsTotal;
        } else if (mapping.column === "totalPoints") {
          val = totalPointsTotal;
        }
        totalData[mapping.column] = val;
      });

      return {
        accountKey: participant.accountKey,
        data: totalData
      };
    });
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
