import { db } from '@/firebase'

const state = {
  activeSecMenu: {},
  siteDrawer: false,
}

const actions = {
  setActiveSecMenu ({ commit }, payload) {
    commit('setActiveSecMenu', payload)
  },
  async fetchScreenData ({ commit }, payload) {
    const dbRef = db.collection('programs').doc(this.getters.programId)
    const querySnapshot = await dbRef.collection('pages')
    .doc(payload.contentId)
    .get()
    commit('setActiveSecMenu', querySnapshot.data())
  },
  setSiteDrawer ({commit}, payload) {
    commit('setSiteDrawer', payload)
  },
}

const mutations = {
  setActiveSecMenu (state, payload) {
    state.activeSecMenu = payload
  },
  setSiteDrawer (state, payload) {
    state.siteDrawer = payload
  },
}

const getters = {
  activeSecMenu (state) {
    return state.activeSecMenu
  },
  siteDrawer (state) {
    return state.siteDrawer
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}
