/* eslint-disable no-console */
import { db } from '@/firebase'

const state = {
  awards: [],
  allAwardData: [],
  loadingAwards: false,
  memberQualifiedAwardData: []
}

const actions = {
  async loadAwards ({commit, getters}) {
    commit('setLoadingTable', true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection('programs')
        .doc(getters.programId)
        .collection('awards')
        .get()
    } catch(e) {
      querySnapshot = [];
    }

    const awards = []
    querySnapshot.forEach(doc => {
      
      awards.push({
        id: doc.id,
        award: doc.data().award,
        selectedParticipantType: doc.data().selectedParticipantType,
        conditions: doc.data().conditions || [],
        awardUppercase: doc.data().awardUppercase,
        selectedDatabucket: doc.data().selectedDatabucket,
        isDefault: doc.data().isDefault,
        externalImageUrl: doc.data().externalImageUrl || '',
        image: doc.data().image || {
          name: '',
          updated: '',
          smallUrl: '',
          mediumUrl: '',
          largeUrl: '',
          smallStoragePath: '',
          mediumStoragePath: '',
          largeStoragePath: '',
        }
      });
    });

    commit('setAwards', awards);
    commit('setLoadingTable', false);
  },

  async loadAllAwardData ({ commit, getters }) {
    commit('setLoadingTable', true);
    commit('setAllAwardData', [])
    let awardsSnapshot;
    try {
      awardsSnapshot = await db
        .collection('programs')
        .doc(getters.programId)
        .collection('awards')
        .get()
    } catch(e) {
      awardsSnapshot = [];
    }
    const databuckets = []
    awardsSnapshot.forEach(function (doc) {
      if (doc.data().selectedDatabucket) {
        databuckets.push({
          awardId: doc.id,
          id: doc.data().selectedDatabucket,
          conditions: doc.data().conditions,
          image: doc.data().image,
          award: doc.data().award,
          participantType: doc.data().selectedParticipantType
      });
      }
    });

    let allAwardData = []

    for (const databucket of databuckets) {
      let dataRef
      try {
        dataRef = db.collection('programs')
          .doc(getters.programId)
          .collection('databuckets')
          .doc(databucket.id)
          .collection('results')
          .doc('total')
      } catch(e) {
        dataRef = null;
      }

      const totalsShapshot = await dataRef.get()
      let jsonString
      if (totalsShapshot.exists) {
        jsonString = totalsShapshot.data().csvData
      }
      const nestedArray = JSON.parse(jsonString);
      const labels = nestedArray[0];
      // console.log(labels)

      const camelLabels = labels.map((element) => {
        const words = element.split(' ');
        const camelCaseElement = words.map((word, index) => {
          if (index === 0) {
            return word.toLowerCase();
          }
          return word.charAt(0).toUpperCase() + word.slice(1);
        }).join('');
        return camelCaseElement;
      });
      // console.log(camelLabels)

      const objectsArray = nestedArray.slice(1).map(row => {
        const object = {};
        camelLabels.forEach((label, index) => {
          object[label] = row[index];
        });
        object['databucketId'] = databucket.id;
        object['participantType'] = databucket.participantType;
        object['conditions'] = databucket.conditions;
        object['image'] = databucket.image;
        object['award'] = databucket.award;
        object['awardId'] = databucket.awardId;
        return object;
      });
      allAwardData.push(...objectsArray)
    }

    commit('setAllAwardData', allAwardData)
    commit('setLoadingTable', false);
  },

  async loadMemberQualifiedAwardData({ dispatch, commit, getters }) {
    await dispatch('loadAllAwardData');
    const awardData = getters.allAwardData.filter(el => el.participantType === 'Member')
    let results = []
    awardData.forEach((award) => {
      
      let found = false

      award.conditions.forEach(condition => {
        condition
        const targetVal = award.target
        const actualVal = award.actual
        const diffVal = award.difference
        const percentVal = award.percentage
        
        const realStatement = condition.statement
          .replace(/__actual/ig, actualVal)
          .replace(/__target/ig, targetVal)
          .replace(/__percent/ig, percentVal)
          .replace(/__diff/ig, diffVal);

        const qualified = Function('return' + realStatement)();
        if (qualified === true) {
          found = true
          return;
        }
      })

      results.push({
        ...award,
        qualified: found
      })
    })
    const matchedArray = [];

    results.filter(el => el.qualified === true).forEach(award => {
      const matchingMember = getters.members.find(member => member.accountKey === award.accountId);
      if (matchingMember) {
        const matchedItem = {
          ...award,
          fullname: matchingMember.fullname,
          email: matchingMember.email,
          memberId: matchingMember.id
        };
        matchedArray.push(matchedItem);
      }
    })
    commit('setMemberQualifiedAwardData', matchedArray)
  },

  checkQualified(payload) {
    console.log(payload)
    let found = false
    const conditions = payload.conditions
    conditions.forEach(condition => {
      condition
      const targetVal = payload.target
      const actualVal = payload.actual
      const diffVal = payload.difference
      const percentVal = payload.percentage
      
      const realStatement = condition.statement
        .replace(/__actual/ig, actualVal)
        .replace(/__target/ig, targetVal)
        .replace(/__percent/ig, percentVal)
        .replace(/__diff/ig, diffVal);
      
      // console.log(realStatement)
      const qualified = Function('return' + realStatement)();
      // console.log(qualified)
      if (qualified === true) {
        found = true
        return;
      }
    })
    return found
  },

  async createAward({ dispatch, commit, getters }, payload) {
    console.log(payload)
    const awardsRef = db
      .collection('programs')
      .doc(getters.programId)
      .collection('awards');

    let titleDupSnapshot
    try {
      titleDupSnapshot = await awardsRef.where('awardUppercase', '==', payload.awardUppercase).get();
    } catch(e) {
      throw 'Error occured when checking the award existency.';
    }

    if (titleDupSnapshot.size > 0) {
      throw 'Award is already registered.';
    }

    const { id, ...awardInput } = payload; // eslint-disable-line no-unused-vars
    const award = {
      ...awardInput,
    }

    let newAwardRef;
    try {
      newAwardRef = await awardsRef.add(award);
    } catch(e) {
      throw 'Error occured when creating a new award.';
    }

    commit('createAward', { ...awardInput, id: newAwardRef.id });
    dispatch('setSnackbar', 'Award Created.');
  },

  async updateAward({ dispatch, commit, getters }, payload) {
    const awardsRef = db
      .collection('programs')
      .doc(getters.programId)
      .collection('awards');

    let storedAward;
    try {
      const awardDoc = await awardsRef.doc(payload.id).get();
      storedAward = awardDoc.data();
    } catch(e) {
      storedAward = null;
    }

    if (!storedAward) {
      throw 'Error occured when fetching the award info';
    }

    let titleDupSnapshot
    try {
      titleDupSnapshot = await awardsRef.where('awardUppercase', '==', payload.awardUppercase).get();
    } catch(e) {
      throw 'Error occured when checking the award existency.';
    }

    if (titleDupSnapshot.size > 0) {
      let duplicated = false;
      titleDupSnapshot.forEach((doc) => {
        if (doc.id !== payload.id) {
          duplicated = true;
        }
      })
      if (duplicated) {
        throw 'Award is already registered.';
      }
    }

    const { id, ...awardInput } = payload;
    const award = {
      ...awardInput,
    }

    try {
      await awardsRef.doc(id).update(award);
    } catch(e) {
      console.error(e);
      throw 'Error occured when updating a award';
    }

    commit('updateAward', payload);
    dispatch('setSnackbar', 'Award Updated.');
  },

  async deleteAward( { dispatch, commit, getters }, awardId) {
    try {
      await db
        .collection('programs')
        .doc(getters.programId)
        .collection('awards')
        .doc(awardId)
        .delete();
    } catch (e) {
      throw 'Error occured when deleting a award';
    }
    commit('deleteAward', awardId);
    dispatch('setSnackbar', 'Award Deleted.');
  },

}

const mutations = {
  setAwards (state, payload) {
    state.awards = payload
  },

  setAllAwardData (state, payload) {
    state.allAwardData = payload
  },

  setMemberQualifiedAwardData (state, payload) {
    state.memberQualifiedAwardData = payload
  },

  setLoadingTable(state,payload) {
    state.loadingAwards = payload;
  },

  createAward (state, payload) {
    state.awards = [...state.awards, payload];
  },

  updateAward(state, payload) {
    state.awards = state.awards.map((item) => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteAward(state, payload) {
    state.awards = state.awards.filter(item => item.id !== payload);
  },

}

const getters = {
  awards (state) {
    return state.awards;
  },
  allAwardData (state) {
    return state.allAwardData;
  },
  memberQualifiedAwardData (state) {
    return state.memberQualifiedAwardData;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
