export const ROLES_DICT = {
  super: 'Super',
  admin: 'Admin',
  editor: 'Editor',
  observer: 'Observer',
};

export const ADMIN_ROLES = [
  ROLES_DICT.admin,
  ROLES_DICT.editor,
  ROLES_DICT.observer,
];

export const ALL_ROLES = [
  ROLES_DICT.super,
  ROLES_DICT.admin,
  ROLES_DICT.editor,
  ROLES_DICT.observer,
];
          
export const ORG_ROLES_DICT = {
  owner: 'Owner',
  administrator: 'Administrator',
  client: 'Client',
};

export const ORG_ROLES = [
  ORG_ROLES_DICT.owner,
  ORG_ROLES_DICT.administrator,
  ORG_ROLES_DICT.client,
];