import Vue from "vue";
import Router from "vue-router";
import { store } from "./store";

// import moment from 'moment'

Vue.use(Router);

const authGuard = (to, from, next) => {
  if (store.getters.adminToken && store.getters.currentUser) {
    //console.log("adminUser");
    store.dispatch("revalAdmin");
    return next();
  } else if (store.getters.currentUser) {
    // console.log("");
    return next();
  } else {
    // console.log("notSignedIn");
    next("/signin");
  }
};

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Root",
      redirect: "/home"
    },
    {
      path: "/demo/:id/:token",
      name: "Demo",
      component: () => import("@/views/Demo")
    },
    {
      path: "/signin",
      name: "Signin",
      meta: {
        title: "Sign In"
      },
      component: () => import("@/components/Design/View/ViewSignIn")
    },
    {
      path: "/signup",
      name: "Signup",
      meta: {
        title: "Sign Up"
      },
      component: () => import("@/components/Design/View/ViewSignUp")
    },
    {
      path: "/home",
      name: "home",
      meta: {
        title: "Home"
      },
      component: () => import("@/views/Home"),
      beforeEnter: authGuard
    },
    {
      path: "/articles/:id",
      name: "articles",
      meta: {
        title: "Articles"
      },
      component: () => import("@/views/Articles"),
      beforeEnter: authGuard
    },
    {
      path: "/rewards/:id",
      name: "rewards",
      meta: {
        title: "Rewards"
      },
      component: () => import("@/views/Rewards"),
      beforeEnter: authGuard
    },
    {
      path: "/offers",
      name: "offers",
      component: () => import("@/views/Offers"),
      beforeEnter: authGuard
    },
    {
      path: "/quizzes",
      name: "quizes",
      component: () => import("@/views/Quizzes"),
      beforeEnter: authGuard
    },
    {
      path: "/profile",
      name: "profile",
      component: () => import("@/views/Profile"),
      beforeEnter: authGuard
    },
    {
      path: "/page/:id",
      name: "page",
      component: () => import("@/views/Page"),
      beforeEnter: authGuard
    }
  ]
});

router.beforeEach((to, from, next) => {
  const title = store.getters.currentProgram.displayTitle;
  document.title = `${title}` || "Incentable";
  return next();
});

// Bootstrap Analytics
// Set in .env
// https://github.com/MatteoGabriele/vue-analytics
if (process.env.VUE_APP_GOOGLE_ANALYTICS) {
  Vue.use(require("vue-analytics").default, {
    id: process.env.VUE_APP_GOOGLE_ANALYTICS,
    router,
    autoTracking: {
      page: process.env.NODE_ENV !== "development"
    }
  });
}

export default router;
