import Vue from "vue";
import VueI18n from "vue-i18n";
import { store } from "../store";

Vue.use(VueI18n);
//

const i18n = new VueI18n({
  locale: "en", // set locale
  silentTranslationWarn: true,
  fallbackLocale: "en", // set fallback locale
  messages: store.state.translate.translationData // set locale messages
});

export default i18n;
