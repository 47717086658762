/* eslint-disable no-console */
import { db } from '@/firebase'

const state = {
  companyTags: [],
}

const actions = {
  async loadCompanyTags ({commit, getters}) {
    //commit('setLoadingTable', true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection('programs')
        .doc(getters.programId)
        .collection('companyTags')
        .get()
    } catch(e) {
      querySnapshot = [];
    }

    const companyTags = []
    querySnapshot.forEach(function (doc) {
      companyTags.push({
        id: doc.id,
        tag: doc.data().tag,
        tagUppercase: doc.data().tagUppercase,
      });
    });

    commit('setCompanyTags', companyTags);
    //commit('setLoadingTable', false);
  }
}

const mutations = {
  setCompanyTags (state, payload) {
    state.companyTags = payload
  }
}

const getters = {
  companyTags (state) {
    return state.companyTags;
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
