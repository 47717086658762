/* eslint-disable no-console */
import { storage } from '@/firebase'

const state = {
}

const actions = {

  async deleteMediaFilesFromStorage (state, image) {
    console.log(image.smallStoragePath)
    try {
      var storageRef = storage.ref()
      var storageRefSmall = storageRef.child(image.smallStoragePath)
      var storageRefMedium = storageRef.child(image.mediumStoragePath)
      var storageRefLarge = storageRef.child(image.largeStoragePath)

      await storageRefSmall.delete().then(() => {
      }).catch((error) => {
        console.log(error)
      });

      await storageRefMedium.delete().then(() => {
      }).catch((error) => {
        console.log(error)
      });

      await storageRefLarge.delete().then(() => {
      }).catch((error) => {
        console.log(error)
      });
    } catch (e) {
      throw 'error when deleting an image';
    }
    return 'ok'
  },

}

const mutations = {
}

const getters = {
}

export default {
  state,
  getters,
  actions,
  mutations
}