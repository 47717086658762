/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import numeral from "numeral";

const state = {
  transactions: []
};

const actions = {
  async loadTransactions({ commit, getters }, payload) {
    //commit("setLoadingPointsTable", true);
    const userId = payload;

    const dbRef = db.collection("programs").doc(getters.programId);

    dbRef
      .collection("members")
      .doc(userId)
      .collection("transactions")
      .onSnapshot(
        querySnapshot => {
          let transactions = [];
          querySnapshot.forEach(doc => {
            transactions.push({
              id: doc.id,
              ...doc.data()
            });
          });
          commit("setTransactions", transactions);
        },
        error => {
          console.log(error);
        }
      );

    //commit("setLoadingPointsTable", false);
  },

  async getRedeemableTransactionsByDatabucket({ getters, commit }) {
    commit("setLoadingDatabucketRedeemableTransactions", true);
    commit("setDatabucketRedeemableTransactions", []);
    const id = getters.databucket.id;
    console.log(id);
    try {
      const membersSnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("members")
        .get();

      const transactions = [];

      for (const memberDoc of membersSnapshot.docs) {
        const firstname = memberDoc.data().firstname
          ? memberDoc.data().firstname
          : "";
        const lastname = memberDoc.data().lastname
          ? memberDoc.data().lastname
          : "";

        const transactionsSnapshot = await memberDoc.ref
          .collection("transactions")
          .where("databucketId", "==", id)
          .get();
        transactionsSnapshot.forEach(transactionDoc => {
          const transactionData = {
            ...transactionDoc.data(),
            member: firstname + " " + lastname
          };
          transactions.push(transactionData);
        });
      }
      console.log(transactions);
      commit("setDatabucketRedeemableTransactions", transactions);
      commit("setLoadingDatabucketRedeemableTransactions", false);
      // dispatch("setSnackbar", "Data Bucket updated");
    } catch (error) {
      commit("setLoadingDatabucketRedeemableTransactions", false);
      console.error("Error fetching transactions:", error);
      throw error; // Rethrow the error for error handling at the component level
    }
  },
  // add points to member transactions then sync balances on the member doc
  async addRedeemablePointsFromDatabucket({ getters, dispatch }, payload) {
    console.log(payload);
    try {
      const data = payload.totalResult;

      const findIndexInFirstRow = searchValue =>
        data[0].findIndex(value => value === searchValue);

      const rowToObject = row => ({
        accountKey: row[accountIdIndex],
        points: row[targetPointsIndex]
      });

      // let memberIdsArr = [];
      const accountIdIndex = findIndexInFirstRow("Account Id");
      const targetPointsIndex = findIndexInFirstRow("Target Points");

      const arrayOfObjects = data.slice(1).map(rowToObject);

      const batchLimit = 500;
      const totalTransactions = arrayOfObjects.length;

      const batchPromises = [];
      let currentBatch = db.batch();

      for (let i = 0; i < totalTransactions; i++) {
        const { accountKey, points } = arrayOfObjects[i];

        const membersRef = db
          .collection("programs")
          .doc(getters.programId)
          .collection("members")
          .where("accountKey", "==", accountKey);

        const membersSnapshot = await membersRef.get();

        if (!membersSnapshot.empty) {
          const memberDoc = membersSnapshot.docs[0];
          const memberId = memberDoc.id;

          const previousTransactionsSnapshot = await memberDoc.ref
            .collection("transactions")
            .where("databucketId", "==", payload.databucketId)
            .get();
          const previousTransactions = [];

          previousTransactionsSnapshot.forEach(doc => {
            const prevTransactionData = doc.data();
            prevTransactionData.transactionId = doc.id;
            previousTransactions.push(prevTransactionData);
          });

          const previousPoints = previousTransactions.reduce(
            (targetPoints, trans) => targetPoints + trans.points,
            0
          );

          const pointsToAllocate = points - previousPoints;

          const newTransaction = {
            points: pointsToAllocate,
            databucketId: payload.databucketId,
            description: "Data Bucket",
            created: new Date(),
            updated: new Date(),
            updatedBy: payload.memberId,
            createdBy: payload.memberId,
            notes: payload.databucketTitle,
            type: "Award",
            memberId: memberId,
            accountKey: accountKey
          };

          if (pointsToAllocate !== 0) {
            currentBatch.set(
              memberDoc.ref.collection("transactions").doc(),
              newTransaction
            );
            // memberIdsArr.push(memberId);
          }

          if ((i + 1) % batchLimit === 0 || i === totalTransactions - 1) {
            batchPromises.push(currentBatch.commit());
            currentBatch = db.batch();
          }
        } else {
          console.error(`Member with accountKey ${accountKey} not found.`);
        }
      }

      try {
        await Promise.all(batchPromises);
      } catch (e) {
        throw "Error when adding redeemable points from a databucket";
      }

      //   try {
      //     console.log(memberIdsArr);
      //     await dispatch("updateMemberTotals", memberIdsArr);
      //   } catch (e) {
      //     throw "Error when creating a new transaction";
      //   }
      //
    } catch (error) {
      console.error("Error adding transactions:", error);
    }
  },

  async revokeRedeemablePointsByDatabucket({ getters, commit, dispatch }) {
    console.log("removing all redeemable points");
    commit("setRevokeRedeemablePointsProcessing", true);
    const databucketId = getters.databucket.id;
    const databucketTitle = getters.databucket.title;
    try {
      const membersSnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("members")
        .get();

      const memberTransactions = [];

      for (const memberDoc of membersSnapshot.docs) {
        const accountKey = memberDoc.data().accountKey;
        const memberId = memberDoc.id;
        const transactionsSnapshot = await memberDoc.ref
          .collection("transactions")
          .where("databucketId", "==", databucketId)
          .get();

        let totalPoints = 0;

        transactionsSnapshot.forEach(transactionDoc => {
          const transactionData = transactionDoc.data();
          totalPoints += transactionData.points; // Summing the points
        });

        memberTransactions.push({
          memberId,
          databucketId,
          databucketTitle,
          accountKey,
          points: totalPoints
        });
      }

      commit("setRevokeRedeemablePointsProcessing", false);
      // dispatch("setSnackbar", "Data Bucket updated");
      console.log(memberTransactions); // Return the array of member transactions
      // post actual adjustment to member transactions
      try {
        const batchLimit = 500;
        const totalTransactions = memberTransactions.length;

        const batchPromises = [];
        // let memberIdsArr = [];
        let currentBatch = db.batch();

        for (let i = 0; i < totalTransactions; i++) {
          const { accountKey, points } = memberTransactions[i];

          const membersRef = db
            .collection("programs")
            .doc(getters.programId)
            .collection("members")
            .where("accountKey", "==", accountKey);

          const membersSnapshot = await membersRef.get();

          if (!membersSnapshot.empty) {
            const memberDoc = membersSnapshot.docs[0];
            const memberId = memberDoc.id;

            const pointsToAllocate = 0 - points;

            const newTransaction = {
              points: pointsToAllocate,
              databucketId: databucketId,
              description: "Data Bucket",
              created: new Date(),
              updated: new Date(),
              updatedBy: payload.memberId,
              createdBy: payload.memberId,
              notes: databucketTitle,
              type: "Award",
              memberId: memberId,
              accountKey: accountKey
            };

            if (pointsToAllocate !== 0) {
              currentBatch.set(
                memberDoc.ref.collection("transactions").doc(),
                newTransaction
              );
              // memberIdsArr.push(memberId);
            }

            if ((i + 1) % batchLimit === 0 || i === totalTransactions - 1) {
              batchPromises.push(currentBatch.commit());
              currentBatch = db.batch();
            }
          } else {
            console.error(`Member with accountKey ${accountKey} not found.`);
          }
        }

        try {
          await Promise.all(batchPromises);
        } catch (e) {
          throw "Error when adding redeemable points from a databucket";
        }

        //   try {
        //     await dispatch("updateMemberTotals", memberIdsArr);
        //   } catch (e) {
        //     throw "Error when creating a new transaction";
        //   }
      } catch (error) {
        console.error("Error adding transactions:", error);
      }
    } catch (error) {
      commit("setRevokeRedeemablePointsProcessing", false);
      console.error("Error fetching member transactions:", error);
      throw error; // Rethrow the error for error handling at the component level
    }
  },

  async addMemberPoints({ commit, getters, dispatch }, payload) {
    // required values in payload....
    // points: integer (must be a positive value)
    // type: Award, Redeem, Adjust, Refund, Reorder
    // notes: 'description of the transaction. EG Admin'
    // description: 'description of the source transaction. EG Databucket name or Checkout or a value entered by the admin user'
    // memberId: the member firebase doc id
    console.log(payload);
    const transactionsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members")
      .doc(payload.memberId)
      .collection("transactions");

    const transaction = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: payload.memberId,
      updatedBy: payload.memberId
    };

    try {
      await transactionsCollection.add(transaction);
    } catch (e) {
      throw "Error when creating a new transaction";
    }

    // try {
    //   await dispatch("updateMemberTotals", [payload.memberId]);
    // } catch (e) {
    //   throw "Error when updating member totals";
    // }
    //

    commit("setSnackbar", "Points added");

    return "ok";
  },

  async subtractMemberPoints({ commit, getters, dispatch }, payload) {
    // required values in payload....
    // points: integer (must be a positive value as it gets converted to negative below)
    // type: Award, Redeem, Adjust, Refund, Reorder
    // notes: 'description of the transaction. EG 3 reward items'
    // description: 'description of the source transaction. EG Databucket name or Checkout'
    // memberId: the member firebase doc id
    // console.log(payload)
    const transactionsCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members")
      .doc(payload.memberId)
      .collection("transactions");

    const transaction = {
      ...payload,
      points: -Math.abs(payload.points),
      created: timestamp,
      updated: timestamp,
      createdBy: payload.memberId,
      updatedBy: payload.memberId
    };

    try {
      await transactionsCollection.add(transaction);
    } catch (e) {
      throw "Error when creating a new transaction";
    }

    // try {
    //   await dispatch("updateMemberTotals", [payload.memberId]);
    // } catch (e) {
    //   throw "Error when udating member points balances";
    // }
    //
    commit("setSnackbar", "Order submitted");

    return "ok";
  },

  // async updateMemberTotals({ getters }, memberIds) {
  //   try {
  //     const batchSize = 500; // Maximum batch size allowed by Firestore
  //     const totalMembers = memberIds.length;
  //     // Loop through the provided member IDs
  //     for (let i = 0; i < totalMembers; i += batchSize) {
  //       const batch = db.batch();
  //       const batchMemberIds = memberIds.slice(i, i + batchSize);
  //
  //       for (const memberId of batchMemberIds) {
  //         // Get the member document reference
  //         const memberRef = db
  //           .collection("programs")
  //           .doc(getters.programId)
  //           .collection("members")
  //           .doc(memberId);
  //
  //         // Get the transactions sub-collection
  //         const transactionsSnapshot = await memberRef
  //           .collection("transactions")
  //           .get();
  //
  //         // Initialize totals
  //         let balanceTotal = 0;
  //         let awardedTotal = 0;
  //         let redeemedTotal = 0;
  //
  //         // Loop through transactions and calculate totals
  //         transactionsSnapshot.forEach(transactionDoc => {
  //           const transactionData = transactionDoc.data();
  //           const transactionPoints = transactionData.points;
  //
  //           // Update balance total for all types
  //           balanceTotal += transactionPoints;
  //
  //           // Update awarded total for Award and Adjust types
  //           if (
  //             transactionData.type === "Award" ||
  //             transactionData.type === "Refund" ||
  //             transactionData.type === "Adjust"
  //           ) {
  //             awardedTotal += transactionPoints;
  //           }
  //
  //           // Update redeemed total for Reorder, Refund, and Redeem types
  //           if (
  //             transactionData.type === "Reorder" ||
  //             transactionData.type === "Redeem"
  //           ) {
  //             redeemedTotal += transactionPoints;
  //           }
  //         });
  //
  //         const updateData = {
  //           balance: balanceTotal,
  //           awarded: awardedTotal,
  //           redeemed: redeemedTotal
  //         };
  //         batch.update(memberRef, updateData);
  //       }
  //       await batch.commit();
  //     }
  //   } catch (error) {
  //     console.error("Error updating member totals:", error);
  //   }
  // },

  async deleteTransaction({ commit, getters }, payload) {
    const docRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members")
      .doc(payload.memberId)
      .collection("transactions")
      .doc(payload.transactionId);

    try {
      await docRef.delete();
      // await dispatch("updateMemberTotals", [payload.memberId]);
      commit("deleteTransaction", payload.transactionId);
    } catch (e) {
      console.error(e);
      throw "Error occured when updating a member";
    }

    commit("setSnackbar", "Transaction deleted");
    commit("setLoading", false);
  }
};

const mutations = {
  setTransactions(state, payload) {
    state.transactions = payload;
  },
  setMemberPoints(state, payload) {
    state.transactions = [...state.transactions, payload];
  },
  updateTransaction(state, payload) {
    state.transactions = state.transactions.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteTransaction(state, payload) {
    state.transactions = state.transactions.filter(item => item.id !== payload);
  }
};

const getters = {
  transactions(state) {
    return state.transactions;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
