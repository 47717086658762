<template>
    <v-tooltip left>
      <template v-slot:activator="{ on }">
        <v-icon :color="filtered.color" v-on="on">{{ filtered.icon }}</v-icon>
      </template>
      <span class="text-capitalize">{{ filtered.state }}</span>
    </v-tooltip>
</template>

<script>
export default {
  data: () => ({
    statuses: [
      {state: 'Active', icon: 'check_circle', color: 'green'},
      {state: 'Archive', icon: 'inventory', color: 'red'},
      {state: 'Available', icon: 'check_circle', color: 'green'},
      {state: 'Invited', icon: 'drafts', color: 'blue'},
      {state: 'Awaiting Approval', icon: 'how_to_reg', color: 'amber'},
      {state: 'Cancelled', icon: 'not_interested', color: 'red'},
      {state: 'Pending', icon: 'pending', color: 'amber'},
      {state: 'inactive', icon: 'not_interested', color: 'red'},
      {state: 'Inactive', icon: 'not_interested', color: 'red'},
      {state: 'Live', icon: 'wifi_tethering', color: 'green'},
      {state: 'Processing', icon: 'loop', color: 'amber'},
      {state: 'Completed', icon: 'thumb_up', color: 'green'},
      {state: 'Rejected', icon: 'thumb_down', color: 'red'},
      {state: 'Invoiced', icon: 'receipt', color: 'green'},
      {state: 'Draft', icon: 'fas fa-edit', color: 'amber'},
      {state: 'Voided', icon: 'not_interested', color: 'red'},
      {state: 'Deactivated', icon: 'not_interested', color: 'red'},
      {state: 'Copy', icon: 'content_copy', color: 'purple'},
      {state: 'active', icon: 'check_circle', color: 'green'},
      {state: 'canceled', icon: 'cancel', color: 'red'},
      {state: 'paid', icon: 'check_circle', color: 'green'},
      {state: 'draft', icon: 'pending', color: 'amber'},
      {state: 'open', icon: 'lock_open', color: 'amber'},
      {state: 'void', icon: 'block', color: 'red'},
      {state: 'uncollectible', icon: 'report', color: 'red'},
    ]
  }),
  
  props: {
    status: {
      type: String,
      required: true
    }
  },

  computed: {
    filtered () {
      const found = this.statuses.find((item) => {
        return item.state === this.status
      })
      return found
    },
  },

  methods: {
  }
}
</script>