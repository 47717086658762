/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";
import _ from "lodash";

const state = {
  translations: [],
  translationData: []
};

const actions = {
  async loadTranslations({ commit, getters }) {
    // console.log('loading translations...')
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("translations")
        .orderBy("titleUppercase")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const translations = [];
    querySnapshot.forEach(function(doc) {
      translations.push({
        id: doc.id,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        locale: doc.data().locale,
        directory: doc.data().directory,
        status: doc.data().status,
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
        createdBy: doc.data().createdBy,
        updatedBy: doc.data().updatedBy
      });
    });

    commit("setTranslations", translations);
  },

  async loadTranslationData({ commit, getters }) {
    // console.log("loading translation DATA...");
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("translations")
        .get();
    } catch (e) {
      querySnapshot = [];
    }
    const translationData = [];
    querySnapshot.forEach(function(doc) {
      translationData.push({
        locale: doc.data().locale,
        directory: { ...doc.data().directory }
      });
    });
    const result = Object.keys(translationData).reduce(
      (translationObj, key) => {
        const { directory, locale } = translationData[key];
        const directories = Object.keys(directory).reduce(
          (directoryObj, directoryKey) => {
            const { english, value } = directory[directoryKey];
            return { ...directoryObj, [english]: value };
          },
          {}
        );
        return { ...translationObj, [locale]: directories };
      },
      {}
    );
    // console.log(result);
    commit("setTranslationData", result);
  }
};

const mutations = {
  setTranslations(state, payload) {
    state.translations = payload;
  },

  setTranslationData(state, payload) {
    state.translationData = payload;
  }
};

const getters = {
  translations(state) {
    return state.translations;
  },

  translationData(state) {
    return state.translationData;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
