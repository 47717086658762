/* eslint-disable no-console */
import { db } from '@/firebase'
import _ from 'lodash';

const state = {
  companies: [],
  loadingCompanies: false,
}

const actions = {
  async loadCompanies ({commit, getters}) {
    //commit('setLoadingTable', true)
    //commit('setLoadingCompanies', true)
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection('programs')
        .doc(getters.programId)
        .collection('companies')
        .orderBy('titleUppercase')
        .get()
    } catch(e) {
      querySnapshot = [];
    }

    const companies = []
    querySnapshot.forEach(function (doc) {
      companies.push({
        id: doc.id,
        importBatchId: doc.data().importBatchId,
        title: doc.data().title,
        titleUppercase: doc.data().titleUppercase,
        accountKey: doc.data().accountKey || '',
        status: doc.data().status,
        points: doc.data().points,
        tags: doc.data().tags,
        members: doc.data().members,
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate(),
      });
    });

    commit('setCompanies', companies)
    //commit('setLoadingTable', false)
    //commit('setLoadingCompanies', false)
  }
}

const mutations = {
  setCompanies (state, payload) {
    state.companies = payload
  }
}

const getters = {
  companies (state) {
    return state.companies
  }  
}

export default {
  state,
  getters,
  actions,
  mutations
}
