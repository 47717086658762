import { db } from "@/firebase";
const state = {
  rewards: [],
  rewardCategories: []
};
const actions = {
  async loadRewards({ commit, getters }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewards")
        .get();
    } catch (e) {
      querySnapshot = [];
    }
    const rewards = [];
    querySnapshot.forEach(function(doc) {
      const data = rewards.push({
        id: doc.id,
        ...doc.data()
      });
    });
    commit("setRewards", rewards);
  },
  async loadRewardCategories({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("rewardCategories")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const rewardCategories = [];
    querySnapshot.forEach(function(doc) {
      rewardCategories.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created ? doc.data().created.toDate() : "",
        updated: doc.data().updated ? doc.data().updated.toDate() : ""
      });
    });

    commit("setRewardCategories", rewardCategories);
    commit("setLoadingTable", false);
  }
};
const mutations = {
  setRewards(state, payload) {
    state.rewards = payload;
  },
  setRewardCategories(state, payload) {
    state.rewardCategories = payload;
  }
};
const getters = {
  rewards(state) {
    return state.rewards;
  },
  rewardCategories(state) {
    return state.rewardCategories;
  }
};
export default {
  state,
  getters,
  actions,
  mutations
};
