/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  quizCategories: []
};

const actions = {
  async loadQuizCategories({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizCategories")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const quizCategories = [];
    querySnapshot.forEach(function(doc) {
      quizCategories.push({
        id: doc.id,
        ...doc.data(),
        created: doc.data().created ? doc.data().created.toDate() : "",
        updated: doc.data().updated ? doc.data().updated.toDate() : ""
      });
    });

    commit("setQuizCategories", quizCategories);
    commit("setLoadingTable", false);
  },

  async createQuizCategory({ commit, getters }, payload) {
    const quizCategoriesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("quizCategories");

    let existingQuizCategoryResult;
    try {
      existingQuizCategoryResult = await quizCategoriesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing quiz category";
    }

    if (existingQuizCategoryResult.size > 0) {
      return "duplication";
    }

    const quizCategory = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: getters.user.id,
      updatedBy: getters.user.id
    };

    let newCategoryRef;
    try {
      newCategoryRef = await quizCategoriesCollection.add(quizCategory);
    } catch (e) {
      throw "Error when creating a new category";
    }

    // Note: server time is unavailable until we refetch.
    const tempQuizCategory = {
      ...quizCategory,
      id: newCategoryRef.id,
      created: new Date(),
      updated: new Date()
    };

    commit("createQuizCategory", tempQuizCategory);
    commit("setSnackbar", "Category added");

    return "ok";
  },

  async updateQuizCategory({ commit, getters }, payload) {
    const quizCategoriesCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("quizCategories");

    let existingQuizCategoryResult;
    try {
      existingQuizCategoryResult = await quizCategoriesCollection
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error when fetching existing category";
    }

    let duplicated = false;
    existingQuizCategoryResult.forEach(doc => {
      if (doc.id !== payload.id) {
        duplicated = true;
      }
    });
    if (duplicated) {
      return "duplication";
    }

    const quizCategory = {
      ...payload,
      updated: timestamp,
      updatedBy: getters.user.id
    };

    try {
      await quizCategoriesCollection.doc(payload.id).update(quizCategory);
    } catch (e) {
      throw "Error when updating a category";
    }

    const quizCategoryBeforeUpdate = getters.quizCategories.find(
      item => item.id === payload.id
    );
    // Note: server time is unavailable until we refetch.
    const tempQuizCategory = {
      ...quizCategoryBeforeUpdate,
      ...payload,
      updated: new Date(),
      updatedBy: getters.user.id
    };

    commit("updateQuizCategory", tempQuizCategory);
    commit("setSnackbar", "Quiz Category updated");

    return "ok";
  },

  async deleteQuizCategory({ commit, getters }, id) {
    try {
      await db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizCategories")
        .doc(id)
        .delete();
    } catch (e) {
      throw "Error when deleting an quiz category";
    }
    commit("deleteQuizCategory", id);
    commit("setSnackbar", "Quiz Category deleted");
  },

  async importQuizCategories({ getters }, categoriesAry) {
    console.log(categoriesAry);
    const newQuizCategoriesBatch = db.batch();

    categoriesAry.forEach(item => {
      const data = {
        title: item,
        titleUppercase: item.toUpperCase(),
        created: timestamp,
        updated: timestamp,
        createdBy: getters.user.id,
        updatedBy: getters.user.id
      };
      const newQuizCategoriesRef = db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizCategories")
        .doc();
      newQuizCategoriesBatch.set(newQuizCategoriesRef, data);
    });

    try {
      await newQuizCategoriesBatch.commit();
    } catch (e) {
      throw "Quiz categories import batch failed";
    }
  }
};

const mutations = {
  setQuizCategories(state, payload) {
    state.quizCategories = payload;
  },

  createQuizCategory(state, payload) {
    state.quizCategories = [...state.quizCategories, payload];
  },

  updateQuizCategory(state, payload) {
    state.quizCategories = state.quizCategories.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteQuizCategory(state, payload) {
    state.quizCategories = state.quizCategories.filter(
      item => item.id !== payload
    );
  }
};

const getters = {
  quizCategories(state) {
    return state.quizCategories;
  },
  quizCategoriesMap(state) {
    return state.quizCategories.reduce((result, item) => {
      return {
        ...result,
        [item.id]: item.title
      };
    }, {});
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
