import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import authenticationModule from "@/store/modules/authentication";
import offer from "@/store/modules/offer";
import quizzesModule from "@/store/modules/quizzes";
import quizModule from "@/store/modules/quiz";
import quizCategoryModule from "@/store/modules/quizcategory";
import progress from "@/store/modules/progress";
import menus from "@/store/modules/menus";
import program from "@/store/modules/program";
import translateModule from "@/store/modules/translate";
import webtheme from "@/store/modules/webtheme";
import company from "@/store/modules/company";
import companytag from "@/store/modules/companytag";
import member from "@/store/modules/member";
import transaction from "@/store/modules/transaction";
import cart from "@/store/modules/cart";
import rewards from "@/store/modules/rewards";
import order from "@/store/modules/order";
import media from "@/store/modules/media";
import tier from "@/store/modules/tier";
import award from "@/store/modules/award";
import databucket from "@/store/modules/databucket";
import databuckets from "@/store/modules/databuckets";
import tables from "@/store/modules/tables";
import leaderboard from "@/store/modules/leaderboard";

Vue.use(Vuex);

export const store = new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    program: program,
    authentication: authenticationModule,
    offer: offer,
    quizzes: quizzesModule,
    quiz: quizModule,
    quizcategory: quizCategoryModule,
    progress: progress,
    menus: menus,
    translate: translateModule,
    webtheme: webtheme,
    company: company,
    companytag: companytag,
    member: member,
    transaction: transaction,
    cart: cart,
    rewards: rewards,
    order: order,
    media: media,
    tier: tier,
    award: award,
    databucket,
    databuckets,
    tables,
    leaderboard
  },
  state: {
    appTitle: "Incentable",
    snackbar: null,
    drawer: false,
    error: null,
    loading: false,
    firstTimeLoad: true
  },
  mutations: {
    setSnackbar(state, payload) {
      state.snackbar = payload;
    },
    setDrawer(state, payload) {
      state.drawer = payload;
    },
    openDrawer(state) {
      state.drawer = true;
    },
    setError(state, payload) {
      state.error = payload;
    },
    clearError(state) {
      state.error = null;
    },
    clearArrays(state) {
      state.translate.translations = [];
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setFirstTimeLoad(state, payload) {
      state.firstTimeLoad = payload;
    }
  },
  actions: {
    initialize({ commit }) {
      commit("clearArrays");
      commit("setLoading", false);
    },
    setFirstTimeLoad({ commit }, payload) {
      commit("setFirstTimeLoad", payload);
    },
    clearError({ commit }) {
      commit("clearError");
    },
    setSnackbar({ commit }, payload) {
      commit("setSnackbar", payload);
    },
    setLoading({ commit }, payload) {
      commit("setLoading", payload);
    },
    loading(state) {
      return state.loading;
    }
  },
  getters: {
    loading(state) {
      return state.loading;
    },
    snackbar(state) {
      return state.snackbar;
    },
    error(state) {
      return state.error;
    },
    firstTimeLoad(state) {
      return state.firstTimeLoad;
    }
  }
});
