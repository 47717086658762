/* eslint-disable no-console */
import { db } from "@/firebase";

const state = {
  tiers: [],
  allTierData: [],
  memberQualifiedTierData: [],
  companyQualifiedTierData: [],
  defaultMemberTier: [],
  defaultCompanyTier: []
};

const actions = {
  async loadTiers({ commit, getters }) {
    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tiers")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const tiers = [];
    querySnapshot.forEach(doc => {
      tiers.push({
        id: doc.id,
        tier: doc.data().tier,
        selectedParticipantType: doc.data().selectedParticipantType,
        conditions: doc.data().conditions || [],
        tierUppercase: doc.data().tierUppercase,
        selectedDatabucket: doc.data().selectedDatabucket,
        isDefault: doc.data().isDefault,
        externalImageUrl: doc.data().externalImageUrl || "",
        image: doc.data().image || {
          name: "",
          updated: "",
          smallUrl: "",
          mediumUrl: "",
          largeUrl: "",
          smallStoragePath: "",
          mediumStoragePath: "",
          largeStoragePath: ""
        }
      });
    });

    const defaultMemberTier = tiers
      .filter(el => el.isDefault === true)
      .filter(el => el.selectedParticipantType === "Member");
    commit("setDefaultMemberTier", defaultMemberTier);
    //console.log(defaultMemberTier);

    const defaultCompanyTier = tiers
      .filter(el => el.isDefault === true)
      .filter(el => el.selectedParticipantType === "Company");
    commit("setDefaultCompanyTier", defaultCompanyTier);
    //console.log(defaultCompanyTier);

    commit("setTiers", tiers);
  },

  async loadAllTierData({ commit, getters }) {
    commit("setAllTierData", []);
    let tiersSnapshot;
    try {
      tiersSnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("tiers")
        .get();
    } catch (e) {
      tiersSnapshot = [];
    }
    const databuckets = [];
    tiersSnapshot.forEach(function(doc) {
      if (doc.data().selectedDatabucket) {
        databuckets.push({
          tierId: doc.id,
          id: doc.data().selectedDatabucket,
          conditions: doc.data().conditions,
          image: doc.data().image,
          tier: doc.data().tier,
          participantType: doc.data().selectedParticipantType
        });
      }
    });

    let allTierData = [];

    for (const databucket of databuckets) {
      let dataRef;
      try {
        dataRef = db
          .collection("programs")
          .doc(getters.programId)
          .collection("databuckets")
          .doc(databucket.id)
          .collection("results")
          .doc("total");
      } catch (e) {
        dataRef = null;
      }

      const totalsShapshot = await dataRef.get();
      let jsonString;
      if (totalsShapshot.exists) {
        jsonString = totalsShapshot.data().csvData;
      }
      const nestedArray = JSON.parse(jsonString);
      const labels = nestedArray[0];
      // console.log(labels)

      const camelLabels = labels.map(element => {
        const words = element.split(" ");
        const camelCaseElement = words
          .map((word, index) => {
            if (index === 0) {
              return word.toLowerCase();
            }
            return word.charAt(0).toUpperCase() + word.slice(1);
          })
          .join("");
        return camelCaseElement;
      });
      // console.log(camelLabels)

      const objectsArray = nestedArray.slice(1).map(row => {
        const object = {};
        camelLabels.forEach((label, index) => {
          object[label] = row[index];
        });
        object["databucketId"] = databucket.id;
        object["participantType"] = databucket.participantType;
        object["conditions"] = databucket.conditions;
        object["image"] = databucket.image;
        object["tier"] = databucket.tier;
        object["tierId"] = databucket.tierId;
        return object;
      });
      allTierData.push(...objectsArray);
    }
    commit("setAllTierData", allTierData);
  },

  async loadMemberQualifiedTierData({ dispatch, commit, getters }) {
    await dispatch("loadAllTierData");
    const tierData = getters.allTierData.filter(
      el => el.participantType === "Member"
    );
    let results = [];

    tierData.forEach(tier => {
      let found = false;

      tier.conditions.forEach(condition => {
        condition;
        const targetVal = tier.target;
        const actualVal = tier.actual;
        const diffVal = tier.difference;
        const percentVal = tier.percentage;
        const pointsVal = tier.targetPoints;
        const claimPointsVal = tier.claimPoints;
        const quizPointsVal = tier.quizPoints;
        const totalPointsVal = tier.totalPoints;

        const realStatement = condition.statement
          .replace(/__actual/gi, actualVal)
          .replace(/__target/gi, targetVal)
          .replace(/__percent/gi, percentVal)
          .replace(/__diff/gi, diffVal)
          .replace(/__points/gi, pointsVal)
          .replace(/__offerPoints/gi, claimPointsVal)
          .replace(/__quizPoints/gi, quizPointsVal)
          .replace(/__totalPoints/gi, totalPointsVal);

        const qualified = Function("return" + realStatement)();
        if (qualified === true) {
          found = true;
          return;
        }
      });

      results.push({
        ...tier,
        qualified: found
      });
    });

    const matchedMemberArray = [];
    await dispatch("loadMembers");
    results
      .filter(el => el.qualified === true)
      .forEach(tier => {
        const matchingMember = getters.members.find(
          member => member.accountKey === tier.accountId
        );
        if (matchingMember) {
          const matchedItem = {
            ...tier,
            fullname: matchingMember.fullname,
            email: matchingMember.email,
            memberId: matchingMember.id
          };
          matchedMemberArray.push(matchedItem);
        }
      });
    commit("setMemberQualifiedTierData", matchedMemberArray);
  },

  async loadCompanyQualifiedTierData({ dispatch, commit, getters }) {
    await dispatch("loadAllTierData");
    const tierData = getters.allTierData.filter(
      el => el.participantType === "Company"
    );
    let results = [];
    tierData.forEach(tier => {
      let found = false;

      tier.conditions.forEach(condition => {
        condition;
        const targetVal = tier.target;
        const actualVal = tier.actual;
        const diffVal = tier.difference;
        const percentVal = tier.percentage;
        const pointsVal = tier.targetPoints;
        const claimPointsVal = tier.claimPoints;
        const quizPointsVal = tier.quizPoints;
        const totalPointsVal = tier.totalPoints;

        const realStatement = condition.statement
          .replace(/__actual/gi, actualVal)
          .replace(/__target/gi, targetVal)
          .replace(/__percent/gi, percentVal)
          .replace(/__diff/gi, diffVal)
          .replace(/__points/gi, pointsVal)
          .replace(/__offerPoints/gi, claimPointsVal)
          .replace(/__quizPoints/gi, quizPointsVal)
          .replace(/__totalPoints/gi, totalPointsVal);

        const qualified = Function("return" + realStatement)();
        if (qualified === true) {
          found = true;
          return;
        }
      });

      results.push({
        ...tier,
        qualified: found
      });
    });

    const matchedCompanyArray = [];
    await dispatch("loadCompanies");
    results
      .filter(el => el.qualified === true)
      .forEach(tier => {
        const matchingCompany = getters.companies.find(
          company => company.accountKey === tier.accountId
        );
        if (matchingCompany) {
          const matchedItem = {
            ...tier,
            title: matchingCompany.title,
            companyId: matchingCompany.id,
            companyMembers: matchingCompany.members
          };
          matchedCompanyArray.push(matchedItem);
        }
      });
    //console.log(matchedCompanyArray);
    commit("setCompanyQualifiedTierData", matchedCompanyArray);
  },

  checkQualified(payload) {
    let found = false;
    const conditions = payload.conditions;
    conditions.forEach(condition => {
      condition;
      const targetVal = payload.target;
      const actualVal = payload.actual;
      const diffVal = payload.difference;
      const percentVal = payload.percentage;
      const pointsVal = payload.targetPoints;
      const claimPointsVal = payload.claimPoints;
      const quizPointsVal = payload.quizPoints;
      const totalPointsVal = payload.totalPoints;

      const realStatement = condition.statement
        .replace(/__actual/gi, actualVal)
        .replace(/__target/gi, targetVal)
        .replace(/__percent/gi, percentVal)
        .replace(/__diff/gi, diffVal)
        .replace(/__points/gi, pointsVal)
        .replace(/__offerPoints/gi, claimPointsVal)
        .replace(/__quizPoints/gi, quizPointsVal)
        .replace(/__totalPoints/gi, totalPointsVal);

      // console.log(realStatement)
      const qualified = Function("return" + realStatement)();
      // console.log(qualified)
      if (qualified === true) {
        found = true;
        return;
      }
    });
    return found;
  }
};

const mutations = {
  setTiers(state, payload) {
    state.tiers = payload;
  },

  setAllTierData(state, payload) {
    state.allTierData = payload;
  },

  setMemberQualifiedTierData(state, payload) {
    state.memberQualifiedTierData = payload;
  },

  setCompanyQualifiedTierData(state, payload) {
    state.companyQualifiedTierData = payload;
  },

  setDefaultMemberTier(state, payload) {
    state.defaultMemberTier = payload;
  },
  setDefaultCompanyTier(state, payload) {
    state.defaultCompanyTier = payload;
  }
};

const getters = {
  tiers(state) {
    return state.tiers;
  },
  allTierData(state) {
    return state.allTierData;
  },
  memberQualifiedTierData(state) {
    return state.memberQualifiedTierData;
  },
  companyQualifiedTierData(state) {
    return state.companyQualifiedTierData;
  },
  defaultMemberTier(state) {
    return state.defaultMemberTier;
  },
  defaultCompanyTier(state) {
    return state.defaultCompanyTier;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
