/*eslint no-useless-escape: "error"*/

import { ALL_ROLES } from "@/constants/roles";

export function validateEmail(email) {
  const emailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
  return emailFormat.test(email);
}

export function parseByDelimeter(text, delimeter = ";", removeEmpty = true) {
  if (!text) {
    return [];
  }
  return text
    .split(delimeter)
    .map(item => item.trim())
    .filter(item => {
      return !removeEmpty || !!item;
    });
}

export function getNewArrayItems(oldArray = [], newArray = []) {
  return newArray.filter(item => {
    return !oldArray.find(
      oldItem => oldItem.toUpperCase() === item.toUpperCase()
    );
  });
}

export function convertToNumber(str) {
  const strVal = `${str || "0"}`;
  return parseFloat(strVal.replace(/[^0-9.-]/g, ""));
}

export function isAuthReqFulfilled(required, current) {
  const reqIndex = ALL_ROLES.findIndex(item => item === required);
  const currentIndex = ALL_ROLES.findIndex(item => item === current);
  return currentIndex <= reqIndex;
}

export function getNum(val) {
  const strVal = `${val || "0"}`;
  return parseFloat(strVal.replace(/[^0-9.-]/g, ""));
}
