const state = {
  cart: [],
}

const actions = {
  async addToCart ({commit, state}, payload) {
    // console.log('add to cart', payload)
    const updates = state.cart
    updates.push(payload)
    commit('setCart', updates)
  },
  async clearCart ({commit}) {
    const updates = []
    commit('setCart', updates)
  },
}

const mutations = {
  setCart (state, payload) {
    state.cart = payload
  },
}

const getters = {
  cart (state) {
    return state.cart
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}