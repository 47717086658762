export const AVATAR_COLORS = [
  '#FFA599', 
  '#D39BDE', 
  '#B8BDF5', 
  '#ADDFE0', 
  '#7EA881', 
  '#ADFFE3', 
  '#AADE87', 
  '#F5E6A2'
]
