/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  orders: [],
  loadingOrders: false,
  processingOrder: false
};

const actions = {
  async loadOrders({ commit, getters }) {
    commit("setLoadingOrders", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("orders")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const orders = [];
    querySnapshot.forEach(function(doc) {
      orders.push({
        id: doc.id,
        ...doc.data()
      });
    });

    commit("setOrders", orders);
    commit("setLoadingOrders", false);
  },

  async loadMemberOrders({ commit, getters }, payload) {
    console.log(payload);
    commit("setLoadingMemberOrders", true);
    const userId = payload;

    const dbRef = db.collection("programs").doc(getters.programId);

    dbRef
      .collection("orders")
      .where("member._id", "==", userId)
      .onSnapshot(
        querySnapshot => {
          let orders = [];
          querySnapshot.forEach(doc => {
            orders.push({
              id: doc.id,
              ...doc.data()
            });
          });
          commit("setMemberOrders", orders);
          console.log(orders);
        },
        error => {
          console.log(error);
        }
      );
    commit("setLoadingMemberOrders", false);
  },

  async createOrder({ commit, getters, dispatch }, payload) {
    commit("setProcessingOrder", true);
    const locale = getters.currentProgram.translate.locale;
    const ordersCollection = db
      .collection("programs")
      .doc(getters.programId)
      .collection("orders");

    const order = {
      ...payload,
      created: timestamp,
      updated: timestamp,
      createdBy: payload.member._id,
      updatedBy: payload.member._id,
      locale: locale
    };

    const transaction = {
      points: payload.cartValue,
      type: "Redeem",
      notes: payload.body,
      description: "Reward Order",
      memberId: payload.member._id
    };

    let newOrderRef;
    try {
      newOrderRef = await ordersCollection.add(order);
      dispatch("subtractMemberPoints", transaction);
    } catch (e) {
      console.log("error: ", e);
      throw "error when creating a new order";
    }

    const date = new Date();
    // Note: server time is unavailable until we refetch.
    const tempOrder = {
      ...order,
      id: newOrderRef.id,
      created: new Date(),
      updated: new Date()
    };

    await commit("createOrder", tempOrder);
    await commit("setSnackbar", "Order submitted successfully");
    await commit("setProcessingOrder", false);
    return "ok";
  },

  async updateOrder() {
    // not available in admin yet. Only in admin site
  },

  setProcessingOrder({ commit }, payload) {
    commit("setProcessingOrder", payload);
  }
};

const mutations = {
  setOrders(state, payload) {
    state.orders = payload;
  },

  setMemberOrders(state, payload) {
    state.memberOrders = payload;
  },

  setLoadingOrders(state, payload) {
    state.loadingOrders = payload;
  },

  setLoadingMemberOrders(state, payload) {
    state.loadingMemberOrders = payload;
  },

  setProcessingOrder(state, payload) {
    state.processingOrder = payload;
  },

  createOrder(state, payload) {
    state.orders = [...state.orders, payload];
  },

  updateOrder(state, payload) {
    state.orders = state.orders.map(item => {
      if (item.id === payload.id) {
        return payload;
      }
      return item;
    });
  },

  deleteOrder(state, payload) {
    state.orders = state.orders.filter(item => item.id !== payload);
  }
};

const getters = {
  orders(state) {
    return state.orders;
  },
  memberOrders(state) {
    return state.memberOrders;
  },
  loadingOrders(state) {
    return state.loadingOrders;
  },
  loadingMemberOrders(state) {
    return state.loadingMemberOrders;
  },
  processingOrder(state) {
    return state.processingOrder;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
