/* eslint-disable no-console */
import { db, timestamp } from "@/firebase";

const state = {
  quizzes: []
};

const actions = {
  async loadQuizzes({ commit, getters }) {
    commit("setLoadingTable", true);

    let querySnapshot;
    try {
      querySnapshot = await db
        .collection("programs")
        .doc(getters.programId)
        .collection("quizzes")
        .get();
    } catch (e) {
      querySnapshot = [];
    }

    const quizzes = [];
    querySnapshot.forEach(function(doc) {
      // Add database fields here to customise for specific table
      quizzes.push({
        ...doc.data(),
        id: doc.id,
        created: doc.data().created.toDate(),
        updated: doc.data().updated.toDate()
      });
    });

    commit("setQuizzes", quizzes);
    commit("setLoadingTable", false);
  },

  async createQuiz({ dispatch, commit, getters }, payload) {
    const quizzesRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("quizzes");

    let titleDupSnapshot;
    try {
      titleDupSnapshot = await quizzesRef
        .where("titleUppercase", "==", payload.titleUppercase)
        .get();
    } catch (e) {
      throw "Error occured when checking the title.";
    }

    if (titleDupSnapshot.size > 0) {
      throw "Title is already registered.";
    }

    let totalPoints = 0;
    // Loop through each question
    payload.questions.forEach(question => {
      // Loop through each answer in the question
      question.answers.forEach(answer => {
        // Accumulate the points
        totalPoints += answer.points;
      });
    });

    const quiz = {
      ...payload,
      createdBy: getters.user.id,
      updatedBy: getters.user.id,
      created: timestamp,
      updated: timestamp,
      pointsOffered: totalPoints + payload.passPoints
    };

    let newQuizRef;
    try {
      newQuizRef = await quizzesRef.add(quiz);
    } catch (e) {
      console.error(e);
      throw "error when creating a new data bucket";
    }

    // Note: server time is unavailable until we refetch.
    const tmpQuiz = {
      ...quiz,
      id: newQuizRef.id,
      created: new Date(),
      updated: new Date()
    };

    commit("createQuiz", tmpQuiz);
    dispatch("setSnackbar", "Quiz created");
  }
};

const mutations = {
  setQuizzes(state, payload) {
    state.quizzes = payload;
  },

  createQuiz(state, payload) {
    state.quizzes = [...state.quizzes, payload];
  },
  deleteQuiz(state, payload) {
    state.quizzes = state.quizzes.filter(item => item.id !== payload);
  }
};

const getters = {
  quizzes(state) {
    return state.quizzes;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
