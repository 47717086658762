// import * as firebase from 'firebase'
import { db, auth, timestamp } from "@/firebase";
import Axios from "axios";
import { apiDomain } from "../../config";
import router from "../../router";
import moment from "moment";

const state = {
  currentUser: null,
  programId: null,
  userDealer: [],
  adminToken: null,
  email: "",
  token: null,
  loading: false,
  registrationSuccess: false,
  registrationErrors: []
};

const actions = {
  async getCurrentUser({ commit, dispatch, getters }) {
    const userId = getters.currentUser._id;

    const dbRef = db.collection("programs").doc(this.getters.programId);
    let currentUser;
    const userUnsubscribe = dbRef
      .collection("members")
      .doc(userId)
      .onSnapshot(
        doc => {
          currentUser = {
            ...doc.data(),
            companyTags,
            _id: doc.id,
            phone: doc.phone ? doc.phone : "-",
            avatar: doc.data().externalImageUrl
              ? doc.data().externalImageUrl
              : doc.data().image.smallUrl
          };
          commit("setCurrentUser", currentUser);
        },
        error => {
          console.log(error);
          if (userUnsubscribe) {
            userUnsubscribe();
          }
        }
      );

    let companyTags = [];

    const companiesSnapshot = await Promise.all(
      getters.currentUser.companies.map(companyId => {
        return dbRef
          .collection("companies")
          .doc(companyId)
          .get();
      })
    );
    companiesSnapshot.map(snapshot => {
      const companyData = snapshot.data();
      companyData.tags &&
        companyData.tags.forEach(tag => {
          if (companyTags.indexOf(tag) === -1) {
            companyTags.push(tag);
          }
        });
    });
  },

  revalAdmin({ commit, getters, dispatch }) {
    dispatch("setSnackbar", null);
    const expiry = moment.unix(getters.adminToken.expiry.seconds).toDate();
    const now = moment().toDate();
    if (now > expiry) {
      commit("setCurrentUser", null);
      commit("setAdminToken", null);
      router.push("../../signin");
      // dispatch('setSnackbar', 'Your session has expired')
    } else {
      return "ok";
    }
  },

  adminAuthFirebase({ dispatch }, payload) {
    auth
      .signInAnonymously()
      .then(() => {
        dispatch("adminAuth", payload);
      })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  },

  async adminAuth({ commit, dispatch }, payload) {
    dispatch("setSnackbar", null);
    const dbRef = db.collection("programs").doc(payload.pid);
    let tokenSnapshot;
    tokenSnapshot = await dbRef.collection("tokens").get();
    const tokens = [];
    tokenSnapshot.forEach(function(doc) {
      tokens.push({
        member: doc.data().member,
        token: doc.data().token,
        expiry: doc.data().expiry
      });
    });
    const data = tokens.find(el => el.token === payload.token);
    if (data) {
      const expiry = data.expiry.toDate();
      const now = moment().toDate();
      if (expiry < now) {
        commit("setCurrentUser", null);
        commit("setAdminToken", null);
        router.push("../../signin");
        // dispatch('setSnackbar', 'Your session has now expired - err2')
      } else {
        commit("setAdminToken", data);
        const dbRef = db.collection("programs").doc(this.getters.programId);
        let memberSnapshot;
        try {
          memberSnapshot = await dbRef
            .collection("members")
            .doc(data.member)
            .get();
          const member = memberSnapshot.data();
          const id = memberSnapshot.id;

          let companyTags = [];
          try {
            const companiesSnapshot = await Promise.all(
              member.companies.map(companyId => {
                return dbRef
                  .collection("companies")
                  .doc(companyId)
                  .get();
              })
            );
            companiesSnapshot.map(snapshot => {
              const companyData = snapshot.data();
              companyData.tags &&
                companyData.tags.forEach(tag => {
                  if (companyTags.indexOf(tag) === -1) {
                    companyTags.push(tag);
                  }
                });
            });
          } catch (error) {
            console.log(error);
          }

          const currentMember = {
            ...member,
            companyTags,
            _id: id,
            phone: member.phone ? member.phone : "-"
          };
          commit("setCurrentUser", currentMember);
          dispatch("setLoading", false);
        } catch (e) {
          commit("setCurrentUser", null);
          commit("setAdminToken", null);
          router.push("../../signin");
          console.log("throw 500");
        }
      }
    } else {
      commit("setCurrentUser", null);
      commit("setAdminToken", null);
      router.push("../../signin");
    }
  },

  signUserUpFirebase({ dispatch }, payload) {
    auth
      .signInAnonymously()
      .then(() => {
        try {
          dispatch("setFirstTimeLoad", false);
          dispatch("setLoading", false);
          dispatch("signUserUp", payload);
        } catch (e) {
          console.log(e);
        }
      })
      .catch(e => {
        console.log(e);
      });
  },

  async signUserUp({ commit, dispatch, getters }, payload) {
    commit("setRegistrationSuccess", false);
    commit("setAdminToken", null);
    commit("setAuthLoading", true);

    const membersRef = db
      .collection("programs")
      .doc(getters.programId)
      .collection("members");

    let dupCheckRequests;
    let emailDupSnapshot, accountKeyDupSnapshot;
    let registrationErrors = [];
    var memberDataKeyLabel =
      getters.currentProgram.memberDataKeyLabel || "identifier";

    if (payload.accountKey) {
      dupCheckRequests = [
        membersRef.where("email", "==", payload.email).get(),
        membersRef.where("accountKey", "==", payload.accountKey).get()
      ];
    } else {
      dupCheckRequests = [membersRef.where("email", "==", payload.email).get()];
    }

    try {
      [emailDupSnapshot, accountKeyDupSnapshot] = await Promise.all(
        dupCheckRequests
      );
    } catch (e) {
      throw "Error occured when checking email and account key";
    }

    if (emailDupSnapshot.size > 0) {
      registrationErrors.push("That Email is already registered");
    }

    if (accountKeyDupSnapshot && accountKeyDupSnapshot.size > 0) {
      registrationErrors.push(
        "That " + memberDataKeyLabel + " is already registered"
      );
    }

    if (registrationErrors.length > 0) {
      dispatch("setRegistrationErrors", registrationErrors);
      commit("setAuthLoading", false);
      throw "registration error";
    }

    dispatch("createMember", payload);
  },

  signUserInFirebase({ dispatch }, payload) {
    auth
      .signInAnonymously()
      .then(() => {
        dispatch("signUserIn", payload);
      })
      .catch(error => {
        var errorCode = error.code;
        var errorMessage = error.message;
        console.log(errorCode, errorMessage);
      });
  },

  async signUserIn({ commit, dispatch, getters }, payload) {
    commit("setAdminToken", null);
    commit("setAuthLoading", true);
    const dbRef = db.collection("programs").doc(getters.programId);
    let errorMessage = "";
    try {
      const querySnapshot = await dbRef
        .collection("members")
        .where("email", "==", payload.email)
        .get();
      if (!querySnapshot.empty) {
        let user = {};
        querySnapshot.forEach(async doc => {
          user = doc.data();
          if (user.status && user.status === "Active") {
            // dispatch(getFcmTooken(user))
            try {
              const res = await Axios.post(apiDomain + "/auth/signin", {
                email: payload.email,
                password: payload.password,
                programId: this.getters.programId
              });
              commit("setToken", res.data.token);
              const unsubscribe = dbRef
                .collection("members")
                .where("email", "==", user.email)
                .limit(1)
                .onSnapshot(
                  querySnapshot => {
                    let user = {};
                    querySnapshot.forEach(async doc => {
                      user = doc.data();
                      let companyTags = [];
                      try {
                        const companiesSnapshot = await Promise.all(
                          user.companies.map(companyId => {
                            return dbRef
                              .collection("companies")
                              .doc(companyId)
                              .get();
                          })
                        );
                        companiesSnapshot.map(snapshot => {
                          const companyData = snapshot.data();
                          companyData.tags &&
                            companyData.tags.forEach(tag => {
                              if (companyTags.indexOf(tag) === -1) {
                                companyTags.push(tag);
                              }
                            });
                        });
                      } catch (error) {
                        console.log(error);
                      }
                      user = {
                        ...user,
                        companyTags,
                        _id: doc.id,
                        phone: doc.phone ? doc.phone : "-"
                      };
                      commit("setCurrentUser", user);
                      await dispatch("getProgressData", user);
                      await dispatch("getPrimaryMenus", user);
                      await dispatch("loadTranslationData", user);
                      router.push("/Home");
                      commit("setAuthLoading", false);
                    });
                  },
                  error => {
                    console.log(error);
                    if (unsubscribe) {
                      unsubscribe();
                    }
                  }
                );
            } catch (err) {
              var errorMessage = err.response
                ? err.response.data
                : "Unexpected Error Occured! Please try again later";
              console.log(errorMessage, "-- in sign user in");
              commit("setSnackbar", errorMessage);
              commit("setAuthLoading", false);
            }
          } else if (user.status && user.status === "Pending") {
            errorMessage =
              "Your registration is still pending. For further information contact the program manager";
            console.log(errorMessage, "-- in sign user in");
            commit("setSnackbar", errorMessage);
            commit("setAuthLoading", false);
          } else if (user.status && user.status === "Cancelled") {
            errorMessage = "Your registration is cancelled by the admin";
            console.log(errorMessage, "-- in sign user in");
            commit("setSnackbar", errorMessage);
            commit("setAuthLoading", false);
          } else if (user.status && user.status === "Awaiting Approval") {
            errorMessage =
              "Your registration is waiting for approval by the admin";
            console.log(errorMessage, "-- in sign user in");
            commit("setSnackbar", errorMessage);
            commit("setAuthLoading", false);
          } else if (user.status && user.status === "Invited") {
            errorMessage = "User already invited";
            console.log(errorMessage, "-- in sign user in");
            commit("setSnackbar", errorMessage);
            commit("setAuthLoading", false);
          }
        });
      } else {
        if (!payload.email) {
          errorMessage = "Please enter your email";
        } else if (!payload.password) {
          errorMessage = "Please enter your password";
        } else {
          errorMessage = "User not found";
        }
        console.log(errorMessage, "User not found");
        commit("setSnackbar", errorMessage);
        commit("setAuthLoading", false);
      }
    } catch (err) {
      errorMessage = "User not found";
      console.log(errorMessage, err);
      commit("setSnackbar", errorMessage);
      commit("setAuthLoading", false);
    }
  },

  async getUserDealer({ commit }, user) {
    commit("setUserDealer", []);
    const dbRef = db.collection("programs").doc(this.getters.programId);
    let dealerMap = user.companies;
    if (dealerMap && dealerMap.length !== 0) {
      await Promise.all(
        dealerMap.map(val => {
          return dbRef
            .collection("companies")
            .doc(val)
            .get();
        })
      ).then(queries => {
        const data = queries.map(query => {
          return query.data().title;
        });
        commit("setUserDealer", data);
      });
    }
  },

  logout({ commit }) {
    commit("setProgressData", []);
    commit("setLeaderBoardData", []);
    commit("setTableData", []);
    commit("setPromotionList", []);
    commit("setRewardList", []);
    commit("setPrimaryMenus", []);
    commit("setSecondaryMenus", []);
    commit("setCurrentUser", null);
    commit("setAdminToken", null);
    commit("setToken", null);
    commit("setRegistrationSuccess", false);
  },

  setEmail({ commit }, payload) {
    commit("setEmail", payload);
  },

  setCurrentUser({ commit }, payload) {
    commit("setCurrentUser", payload);
  },

  setAdminToken({ commit }, payload) {
    commit("setAdminToken", payload);
  },

  setUserDealer({ commit }, payload) {
    commit("setUserDealer", payload);
  },

  clearAuthError({ commit }) {
    commit("clearAuthError");
  },

  setAuthLoading({ commit }, payload) {
    commit("setAuthLoading", payload);
  },

  setRegistrationSuccess({ commit }, payload) {
    commit("setRegistrationSuccess", payload);
  },

  setRegistrationErrors({ commit }, payload) {
    commit("setRegistrationErrors", payload);
  }
};

const mutations = {
  setCurrentUser(state, user) {
    state.currentUser = user;
  },
  setUserDealer(state, payload) {
    state.userDealer = payload;
  },
  setEmail(state, payload) {
    state.email = payload;
  },
  setAdminToken(state, payload) {
    state.adminToken = payload;
  },
  setToken(state, payload) {
    state.token = payload;
  },
  setAuthLoading(state, payload) {
    state.loading = payload;
  },
  setRegistrationSuccess(state, payload) {
    state.registrationSuccess = payload;
  },
  setRegistrationErrors(state, payload) {
    state.registrationErrors = payload;
  }
};

const getters = {
  currentUser(state) {
    return state.currentUser;
  },
  userDealer(state) {
    return state.userDealer;
  },
  adminToken(state) {
    return state.adminToken;
  },
  email(state) {
    return state.email;
  },
  token(state) {
    return state.token;
  },
  authLoading(state) {
    return state.loading;
  },
  registrationSuccess(state) {
    return state.registrationSuccess;
  },
  registrationErrors(state) {
    return state.registrationErrors;
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
